.youtube-player {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* background-color: #000;

  -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75); */

  iframe {
    width: 100%;
  }

  .youtube-static-thumbnail {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    
    .play-btn {
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
      z-index: 10;
      @include transition("all 0.2s ease");
      @include opacity(.8);

      &:hover {
        @include opacity(1);
        /* top: -20px;
        bottom: -20px;
        right: -40px;
        left: 0px; */
      }
    }
    
  }
}