.video-annotations-component {
  position: absolute;
  bottom: 0;
  right: 0;
  color: fff;

  &.Motivational {
    width: 30%;
    height: 60%;
  }

  &.Instructional {
    width: 48%;
    height: 100%;
  }


  .active-annotation {
    position: relative;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    color:white;
    z-index: 100;
    text-align: left;


    img {
      width: 100%;
      height: auto;
    }

    h3 {
      font-size: 24px;
    }

    .media {
      width: 100%;
    }

    &.Motivational {
      background: url('../assets/img/general/annotation2.png') no-repeat left bottom;
      background-size: 100%;
      padding-top: 60%;

      .media {
        margin-left: 12%;
      }

      .media-left {
        width: 20%;
      }

      h3 {
        font-size: 1.4vw;
        max-width: 40%;
      }
    }

    &.Instructional {
      background: url('../assets/img/general/annotation1.png') no-repeat left bottom;
      background-size: 100%;
      padding-top: 60%;

      .media {
        margin-left: 40%;
      }

      .media-left {
        width: 15%;
      }

      h3 {
        font-size: 2vw;
        max-width: 40%;
      }
    }
  }
}