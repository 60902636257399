.workout-invitation-page {
  .social-headings {
    font-size: 20px;
    padding-bottom: 20px;
  }

  .social-btn {
    margin: 0 15px;
  }

  .or-text {
    position: relative;
    top: 48px;
    font-weight: 700;
  }

  .social-col {
    padding: 40px 0px;
    background-color: #383838;
  }

  .input-group {
    margin: 0 10px;
  }

  .warning-text {
    padding: 30px 0 15px 0;

    span {
      color: $brand-danger;
    }
  }
}