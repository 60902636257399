.kaltura-player {
  position: relative;
  overflow: hidden;
  /* background-color: #000;

  -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75); */

  .kWidgetIframeContainer {
    overflow: visible !important;
  }
}