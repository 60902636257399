.trainer-page {
  padding-top: 50px;
  //background-color: rgba(0,0,0,0.5);

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    padding-top: 100px;
  }

  .info-wrapper {
    //margin-bottom: 50px;
  }

  .trainer-info {
    padding: 0px 15px;

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: $screen-md-min) {
      padding: 0 40px 0 40px;
    }

    .media {
      margin: 20px 0;

      .media-body {
        padding-top: 15px;
        padding-left: 10px;
      }

      .media-heading {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 34px;
        margin-top: 5px;
      }
    }

    .sports {
      font-weight: 300;
      font-size: 24px;
    }

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      .media {
        text-align: center;
        .media-left{
          display: block;
        }
      }
    }
  }

  .stats {
    margin: 0px 0 20px 0;
    padding: 10px 0;
    border-top: 2px solid #383838;
    border-bottom: 2px solid #383838;

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      text-align: center;
    }

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: $screen-md-min) {
      //margin: 20px 0;
    }
  }

  .avatar-img {
    margin: 0 auto;

    .img-content {
      border-radius: 50%;
      border: 4px solid #fff;
    }
  }

  .trainer-social {
    min-height: 595px;
  }
  
  .social-buttons-container {
    position: relative;
    background-color: rgba(0,0,0,0.3);
    height: 60px;
    width: 100%;

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      background-color: transparent;
      height: auto;
    }
  }

  .social-buttons {
    position: relative;
    float: right;
    margin-top: 10px;

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      float: none;
      text-align: center;
    }
  }

  .btn-social-round {
    width: 40px;
    height: 40px;
    padding: 3px 5px;
    font-size: 24px;

    @media screen and (min-width: 992px) and (max-width: 1250px) {
      margin-right: 4px;
    }
  }

  .bs-tab-list {
    background-color: transparent;
    float: left;
    
    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      float: none;
      text-align: center;
      background-color: rgba(0,0,0,0.3);
      margin-top: 20px;
    }
  }

  .nav > li > a {
    padding: 13px 20px;
    font-weight: 700;
    font-size: 20px;
    min-width: 170px;

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      min-width: auto;
      width: 140px;
      padding: 13px 5px;
    }

    @media screen and (min-width: 992px) and (max-width: 1250px) {
      padding: 15px 2px;
      min-width: 90px;
      font-size: 16px;
    }
  }

  .bs-tab-list {
    &.nav-tabs > li > a {
      color: $text-color;
      border: none;
      border-radius: 0;
      border-top-color: transparent;
      border-top: 4px solid;
      border-top-color: rgba(20, 212, 255, 0);
      box-sizing: border-box;
      @include transition(border-top-color .3s ease);
    }

    &.nav-tabs > li.active > a, &.nav-tabs > li.active > a:hover, &.nav-tabs > li.active > a:focus {
      color: $text-color;
      background-color: transparent;
      border: none;
      border-top-color: transparent;
      border-top: 4px solid;
      border-top-color: rgba(20, 212, 255, 0);
      @include transition(border-top-color .3s ease);
    }

    &.nav-tabs > li.active > a, &.nav-tabs > li > a:hover, &.nav-tabs > li > a:focus {
      border-top-color: rgba(20, 212, 255, 1) !important;
    }
  }

  .promo-video-panel {
    height: 530px;
    overflow: hidden;
    background-color: #000;

    .video-thumb, .kaltura-player, .mwEmbedKalturaIframe, .image-loader.responsive, #EmberYoutube-player{
      height: 530px !important;
    }
  }

  .social-wall-panel {
    min-height: 530px;
  }

  .juicer-social-feed {
    max-height: 500px;
    overflow-y: scroll;
    margin-top: 15px;

    & .juicer-feed.white li.feed-item, & .juicer-feed.user li.feed-item, & .juicer-feed.juicer-widget li.feed-item, & .juicer-feed.colors li.feed-item, & .juicer-feed.colored-icons li.feed-item, & .juicer-feed.gray li.feed-item {
      background-color: rgba(0,0,0,0.3);
      border-color: #727272;
      border-width: 2px;
    }
  }

  .juicer-social-feed::-webkit-scrollbar {
    display: none;
  }

  .trainer-videos {
    background-color: $gray-dark;
    margin-top: 15px;
  }

}