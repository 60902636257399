.star-rating {
  font-size: 18px;

  ul {
    display: inline-block;
    cursor: default;
  }

  li {
    cursor: pointer;
  }

  &.readonly li {
    cursor: default;
  }

  .fa.star-empty {
    color: #fff;
  }

  .fa.star-full, .fa.star-half {
    color: $brand-primary;
  }
}