.user-page {
  padding-top: 50px;

  .top-container.colored-bg {
    animation: rainbow 20s infinite alternate;
    -webkit-animation: rainbow 20s infinite alternate;
  }

  .card {
    padding: 30px;
  }
  .card.hovercard {
    position: relative;
    padding-top: 0;
    overflow: hidden;
    text-align: center;
    height: 210px;
  }

  .avatar-img.image-loader {
    width: 120px;
    height: 120px !important;
    margin: 20px auto 10px auto;

    .img-content {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 4px solid #fff;
    }
  }

  .image-upload {

    .ajax-file-upload-statusbar {
      position: absolute;
      left: 110px;
      top: 12px;
      height: 5px;
      border: none;
      margin: 0 auto;
      max-width: 126px;
      width: 126px;
      padding: 0;

      .ajax-file-upload-progress {
        margin: 0px !important;
        position: relative;
        width: 146px;
        border: none;
        padding: 0px;
        border-radius: 2px;

        .ajax-file-upload-bar {
          height: 6px;
          border-radius: 1px;
        }
      }

      .ajax-file-upload-filename, .ajax-file-upload-abort, .ajax-file-upload-cancel, .ajax-file-upload-green, .ajax-file-upload-red {
        display: none !important;
      }
    }

    .change-profile-pic {
      &:before {
        cursor: pointer !important;
      }
      cursor: pointer !important;
    }
  }

  .card.hovercard .card-info {
    margin-top: 5px;
  }
  .card.hovercard .card-info .card-title {
    padding:0 5px;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
  }

  .tab-pane {
    padding: 30px 0 0 0;
    font-size: 20px;
  }

  .form-control {
    display: block;
    height: 36px;
    padding: 6px 12px;
    font-size: 20px;
    line-height: 1.42857;
    color: #fff;
    background-color: #fff;
    background-image: none;
    border: none;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .help-text {
    text-align: left;
    font-size: 12px;
    color: $brand-primary;
  }

  input[type="text"].form-control, input[type="email"].form-control, input[type="password"].form-control {
    background: transparent;
    padding: 0px;
    border: none;
    border-bottom: solid 2px #969696;
    transition: border 0.3s;
  }

  input[type="text"].form-control:focus,
  input[type="text"].form-control.focus,
  input[type="email"].form-control:focus,
  input[type="email"].form-control.focus,
  input[type="password"].form-control:focus,
  input[type="password"].form-control.focus {
    border-bottom: solid 2px #c9c9c9;
  }

  .info-panel {
    padding: 0 15px;
    max-width: 400px;
    margin: 0 auto;

    .bs-dropdown-component {
      display: block;
      width: 170px;
      margin: 0;

      .btn.btn-primary {
        color: #fff;
        font-weight: 300;
        border-color: $brand-primary;
        background-color: transparent;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid 2px #969696;
        border-radius: 0;
        transition: border 0.3s;
        text-align: left;
        padding-left: 0px;
      }
    }
  }

  .actions {
    position: relative;
    margin: 20px auto 0px auto;
    

    .action {
      display: inline-block;
      color: $brand-primary;
      font-size: 18px;
      @include transition(color .2s ease);

      &:hover, &:focus {
        color: #fff;
      }
    }

    span.divider {
      color: #fff;
      margin: 0 5px;
    }
  }

  .history-table.table > tbody > tr.active {
    background-color: rgba(18, 190, 238, 0.2);
  }

  .history-table.table > tbody > tr.active > td {
    background-color: transparent;
  }

  .history-table.table > tbody > tr.active ~ .active {
    background: transparent; /* Or whatever your default is */
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    padding-top: 100px;
  }

}