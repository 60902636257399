ul.side-user-list {

  .camera-on {
    position: absolute;
    top: 0px;
    right: 45px;
    height: 50px;
    color: rgba(87,68,189, 1);
    padding: 14px 12px 0 0;
  }

  &.horizontal-layout {
    float: none;

    li.side-user {
      display: block;
      position: relative;
      width: 100%;
      max-width: auto;
      height: auto;
      background-color: rgba(255,255,255,0.1);
      padding: 0px;
      margin: 2px 0;
      //cursor: pointer;
      @include transition(background-color .3s ease);

      /* &:hover, &:focus {
        background-color: rgba(255,255,255,0.3);
      } */

      .avatar-img {
        margin: 5px;
        float: left;
      }

      h4.user-name {
        float: left;
        margin: 0;
        line-height: 50px;
      }

      button.user-camera {
        float: right;
        border-radius: 0;
        box-shadow: none;
        min-height: 50px;

        &:hover {
          background-color: rgba(87,68,189, 1) !important;
        }
      }

      /* Small devices (mobiles, 767px) */
      @media (max-width: $screen-sm-min - 1) {
        text-align: center;

        .avatar-img {
          float: none;
          margin: 5px auto;
        }

        h4.user-name {
          float: none;
          line-height: 26px;
        }

        button.user-camera {
          float: none;
          margin-bottom: 8px;
        }
      }
    }
  }

  &.vertical-layout {
    position: relative;
    display: inline-block;

    .camera-on {
      width: 64px;
      left: 0px;
    }

    li.side-user {
      position: relative;
      float: left;
      width: 100%;
      max-width: 150px;
      height: $social-workout-one-user-videos-width;
      background-color: rgba(255,255,255,0.1);
      padding: 0px;
      margin: 0px 2px;
      text-align: center;

      .media-left, .media-right, .media-body {
        display: block;
        //vertical-align: middle;
        width: 100%;
        text-align: center;
        padding: 0;
      }

      .avatar-img {
        margin: 20% auto 2% auto;
      }

      h4 {
        float: none;
        margin: 0;
        line-height: 30px;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        float: none;
        border-radius: 0 0 0 4px;
        box-shadow: none;
        min-height: 50px;

        &:hover {
          background-color: $brand-primary !important;
        }
      }
    }
  }
}