.social-wall-page {
  position: relative;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;


  &.fitnessplans-page {
    padding-top: 0px !important;

    .top-header {
      position: relative;
      max-height: 300px;
      margin-bottom: 15px;
      background: url("../assets/img/general/hero_2.jpg");
      background-repeat: no-repeat !important;
      background-position: top center !important;
      -webkit-background-size: cover !important;
      -moz-background-size: cover !important;
      -o-background-size: cover !important;
      background-size: cover !important;
      padding-top: 50px;

      /* Small devices (tablets, 768px and up) */
      @media (min-width: $screen-sm-min) {
        height: 400px;
        max-height: 400px;
        padding-top: 100px;
      }
    }

    .copy {
      top: 70px;
    }
  }
}