.fitnessplan-card {
  overflow: hidden;
  
  @include transition('opacity .3s ease');

  &.fadeout-effect {
    @include opacity(.5);
  }

  .hidden {
    display: none;
  }

  .card {
    position: relative;
    background-color: #212121;
  }

  .overlay {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background: url(img/gradients/tile_overlay.png) bottom repeat-x;
  

    .overlay-content {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .card-title {
      position: relative;
      left: 4px;
      font-weight: 300;
      font-size: 14px;
      text-shadow: 0px 1px 1px $gray;
    }

    .card-subtitle {
      position: relative;
      background-color: $gray;
      bottom: 0px;
      left: 0px;
      right: 0px;
      padding: 2px 4px;
      font-weight: 300;
      font-size: 12px;
    }
  }

  &.loading {
    background-color: $gray-dark;

    .overlay {
      background: rgba(0,0,0,0.6);
    }
  }
}