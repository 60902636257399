$social-workout-navbar-height: 0px;
$social-workout-footer-height: 0px;

$social-workout-one-user-videos-width: 15%;
$social-workout-two-user-videos-width: 15%;
$social-workout-three-user-videos-width: 15%;

.studio-page {

  .header-btn {
    &:hover {
      background-color: rgba(87,68,189, 1) !important;
    }
  }

  .user-actions {
    position: fixed;
    top: 0;
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    //
   } 
    
  .user-action {
    min-width: 90px;
    height: 50px;
    border-radius: 0 0 4px 4px;
    background-color: rgba(0,0,0,0.3);
    &:hover {
      background-color: rgba(87,68,189, 1) !important;
    }
  }

  .user-actions-right {
    position: fixed;
    right: 0;
    -ms-transform: translateX(0px);
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    @include transition(all .2s ease);

    &.chat-open {
      -ms-transform: translateX(-320px);
      -webkit-transform: translateX(-320px);
      transform: translateX(-320px);
      @include transition(all .2s ease);

      .chat-btn {
        background-color: rgba(87,68,189, 1);

        &:hover, &:focus {
          background-color: rgba(87,68,189, 1) !important;
        }
      }
    }
  }
  
  .guide-btn {
    .fa.fa-calendar {
      font-size: 24px;
    }

    span {
      position:relative;
      top: -4px;
    }
  }

  .fullscreen-btn {
    border-radius: 0 0 4px 4px;
    height: 50px;
  }

  .chat-btn {
    position: relative;
    border-radius: 0 0 0px 4px;
    height: 50px;
    line-height: 30px;

    &:active {
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .btn-text {
      position: relative;
      top: -4px;
    }

    .fa {
      position: relative;
      top: 0px;
      font-size: 28px;

      &.has-unreaded-messages {
        color: rgba(87,68,189, 1);
        /* animation: redBlink 1s;
        animation-iteration-count: 2;
        -webkit-animation: redBlink 1s;
        -webkit-animation-iteration-count: 2; */
      }

      .unreaded-messages {
        position: absolute;
        text-align: center;
        font-family: $font-family-sans-serif;
        font-size: 12px;
        color: white;
        font-weight: 700;
        left: 4px;
        top: 9px;
        width: 20px;
      }
    }
  }
  

  .youtube-player {
    margin: 0 auto;
  }
  
  &.mouse-moved {
    &.horizontal-layout {
      .two-users, .three-users {
        .OT_publisher .OT_edge-bar-item.OT_mode-on {
          top: $social-workout-navbar-height;
        }
      }
    }
  }

  &.player-paused {
    &.horizontal-layout {
      .two-users, .three-users {
        .OT_publisher .OT_edge-bar-item.OT_mode-on {
          top: $social-workout-navbar-height;
        }
      }
    }
  }

  .workout-navbar {
    position: fixed;
    display: inline-block;
    width: auto;
    height: 50px;
    top: 0px;
    left: 0px;
    z-index: 999;
    background-color: rgba(0,0,0,0.3);

    .back-btn {
      background-color: transparent;
      width: 50px;
      height: 50px;
      font-size: 35px;
      font-weight: 700;
      border-radius: 0;
    }
  }

  .users-list-header {
      background-color: rgba(87,68,189, 1);

      .title {
        position: relative;
        float: left;
        font-weight: 700;
        font-size: 18px;
        margin-left: 4px;
      }

      &.horizontal-layout {
        position: relative;
        width: 100%;
        height: 50px;
        margin-bottom: 2px;
        padding: 0px;
        overflow: hidden;
        border-radius: 0;

        .title {
          line-height: 50px;
        }
      }

      &.vertical-layout {
        position: absolute;
        float: left;
        bottom: 0;
        width: 50px;
        height: $social-workout-one-user-videos-width;
        margin-left: 0;
        padding: 0px 10px;
        overflow: hidden;
        border-radius: 0 0px 0 0;

        .title {
          position: relative;
          line-height: 30px;
          margin-left: 0px;
          width: 100%;
          //height: 100%;
          text-align: center;
          top: 50%;
          -ms-transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

          .fa {
            font-size: 30px;
          }
        }
      }

      .back-btn.btn {
        position: relative;
        float: left;
        top: 2px;
        margin: 0;
        border-radius: 0;
        width: 46px;
        height: 46px;
        margin-left: 2px;
        font-size: 40px;
        padding: 0;

        .fa {
          position: relative;
          top: -6px;
        }   
      }
    }



  .workout-main {

    .premium-only {
      text-align: center;
      padding: 0 15px;

      &.with-top-padding {
        padding-top: 20%;
      }
    }
    
    &.youtube {
      height: calc(100% - #{$social-workout-navbar-height} - #{$social-workout-footer-height});
    }

    &.horizontal-layout {
      top: calc(#{$social-workout-navbar-height} / 2);
      
      .webrtc-container {
        max-height: 100%;
        overflow-y: auto;
        top: 0px;
        -ms-transform: none;
        -webkit-transform: none;
        transform: none;
      }

      &.one-user {
        .webrtc-container {
          width: $social-workout-one-user-videos-width;
        }

        .player-container {
          width: calc(100% - #{$social-workout-one-user-videos-width});
        }
      }

      &.two-users {
        .webrtc-container {
          width: $social-workout-two-user-videos-width;
        }

        .player-container {
          width: calc(100% - #{$social-workout-two-user-videos-width});
        }
      }

      &.three-users {
        .webrtc-container {
          width: $social-workout-three-user-videos-width;
        }

        .player-container {
          width: calc(100% - #{$social-workout-three-user-videos-width});
        }
      }
    }

    &.vertical-layout {
      top: $social-workout-navbar-height;
      
      .webrtc-container {
        max-width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        left: 50px;
        //text-align: center;
      }
      
      #users-videos {
        position: relative;
        display: inline-block;
        /* display: inline-block;
        left: 50%;
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%); */
      }

      &.one-user {
        .webrtc-container {
          width: calc(100% - 50px);
          height: $social-workout-one-user-videos-width;
        }

        .player-container {
          width: 100%;
          height: calc(100% - #{$social-workout-one-user-videos-width});
        }
      }

      &.two-users {
        .webrtc-container {
          height: $social-workout-two-user-videos-width;
        }

        .player-container {
          height: calc(100% - #{$social-workout-two-user-videos-width});
        }
      }

      &.three-users {
        .webrtc-container {
          height: $social-workout-three-user-videos-width;
        }

        .player-container {
          height: calc(100% - #{$social-workout-three-user-videos-width});
        }
      }
    }
  }
}

body.studio-page {
  &.chat-open #intercom-container .intercom-launcher-frame {
    -ms-transform: translateX(-320px);
    -webkit-transform: translateX(-320px);
    transform: translateX(-320px);
  }

  .global-flashes-container.studio {
    left: auto;
    right: 10px;
    transform: none;
    top: 78px;
    -ms-transform: translateX(0px);
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  &.chat-open .global-flashes-container.studio, &.studio-upnext-open .global-flashes-container.studio {
    -ms-transform: translateX(-340px);
    -webkit-transform: translateX(-340px);
    transform: translateX(-340px);
    @include transition(all .2s ease);
  }

  #generic-wormhole .chat-box {
    position:fixed;
    height: 100%;
    z-index: 999999;
    top: 0px;
    right: 0;
    @include transition(all .2s ease);
    
    &.opened {
      -ms-transform: translateX(0px);
      -webkit-transform: translateX(0px);
      transform: translateX(0px);
    }

    &.closed {
      -ms-transform: translateX(320px);
      -webkit-transform: translateX(320px);
      transform: translateX(320px);
    }
  }
}

