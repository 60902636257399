/************** @author: AKIS *****************/

/* ---------------------------------------------------------------------- */
/*  Preloader
/* ---------------------------------------------------------------------- */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 1051;
  background-color: rgba(0,0,0,.8);
}

#hoolio-preloader {
  position: absolute;
  height: 60px;
  width: 60px;
  box-sizing: border-box;
  border-radius: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: initial !important;
}
.centered-preloader {
  position: relative;
  height: 500px;
  width: 100%;
}

#hoolio-preloader .curve{ 
  margin:0 auto;
  position: absolute;
  border-top-left-radius:200px;
  float:left;
  margin-left:100px;
}

#hoolio-preloader .curve1 {
  width:40px;
  height:40px;
  top: -65px;
  left:-95px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  -webkit-animation: blink 1s infinite 400ms;
  animation: blink 1s infinite 400ms;
}

#hoolio-preloader .curve2 {
  width:28px;
  height:28px;
  top: -43px;
  left:-89px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);

  -webkit-animation: blink 1s infinite 200ms;
  animation: blink 1s infinite 100ms;
}

#hoolio-preloader .top1 {
  position: absolute;
  width: 5px;
  height: 10px;
  top:-15px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#hoolio-preloader .top2 {
  position: absolute;
  width: 25px;
  height: 5px;
  top:-17px;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
#hoolio-preloader .left {
  position: absolute;
  width: 7px;
  height: 7px;
  top:0px;
  left: -48px;
  right: 0;
  margin: 0 auto;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
#hoolio-preloader .hour {
  width: 5px;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-animation: circel_move 1s infinite linear;
  animation: circel_move 1s infinite linear;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
#hoolio-preloader .hour:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 5px;
  top: 8px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.brand-theme {
  #hoolio-preloader {
    border: 5px solid $brand-primary;
  }
  #hoolio-preloader .curve{ 
    border-top:5px solid $brand-primary;
    border-left:5px solid $brand-primary;
  }
  #hoolio-preloader .top1 {
    background: $brand-primary;
  }
  #hoolio-preloader .top2 {
    background: $brand-primary;
  }
  #hoolio-preloader .left {
    background: $brand-primary;
  }
  #hoolio-preloader .hour:before {
    background: $brand-primary;
  }
}

.white-theme {
  #hoolio-preloader {
    border: 5px solid #fff;
  }
  #hoolio-preloader .curve{ 
    border-top:5px solid $brand-primary;
    border-left:5px solid $brand-primary;
  }
  #hoolio-preloader .top1 {
    background: #fff;
  }
  #hoolio-preloader .top2 {
    background: #fff;
  }
  #hoolio-preloader .left {
    background: #fff;
  }
  #hoolio-preloader .hour:before {
    background: #fff;
  }
}

.trasparent-overlay {
  &.loading-overlay {
    background-color: rgba(0,0,0,0);
  }
}

.locked-view-msg {
  position: absolute;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center !important;

  .msg {
    margin-top: 70px;
    color: #fff;
  }
}

