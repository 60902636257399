.hero-row-item {
  position: relative;
  width: 180px;
  height: 320px;
  margin-right: 6px;
  cursor: pointer;

  .bg-img.image-loader.responsive {
    position: absolute;
    z-index: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100% !important;
    @include animate-duration(.6s);
  }

  .copy {
    z-index: 10;
    padding: 20px 10px;
    width: 100%;
    height: 100%;
    color: #fff;
     @include animate-duration(.6s);

    .title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .desc {
      
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    width: 220px;
    height: 391px;
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    width: 253px;
    height: 452px;
  }
}