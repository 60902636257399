.fit-slider-item-primary {
  height: 100%;

  .primary-content {
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    z-index: 2;

    & > div:first-child {
      z-index: -1;
      height: 100%;
    }

    .expanded-ring {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0px;
      border-width: 4px;
      border-style: solid;
      border-color: $brand-info;
      @include animation-duration(.3s);
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 
    
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    
  }
}

