.explore-page {
  z-index: 2;
  background-color: transparent;
  min-height: 1510px;
  padding-top: 50px;

  h3 {
    font-size: 20px;
    max-width: 1000px;
    margin: 30px auto 10px auto;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .fit-slider .slider-title {
    color: #fff;
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    padding-top: 100px;
    
    h3 {
      font-size: 28px;
    }

    h4 {
      font-size: 20px;
    }

  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {

  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: $screen-lg-min) { 

  }
}

.explore-page-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; 
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  z-index: 0;
}