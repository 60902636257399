.user-main-secondary {
  height: 100%;
}

.user-main-secondary-component {
  position: relative;
  height: 100%;
  
  & > div:first-child {
    background: rgba(0,0,0,0.6);
    min-height: 100%;
  }

  .stats {
    padding-left: 15px;
    padding-right: 0px;

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      height: calc(700px - 300px);
    }

    .top-info {
      position: relative;
      height: 80px;
      text-align: left;
      padding: 10px 15px;

      .title {
        font-size: 10px;
        margin-left: 10px;
      }

      .week-range-component {
        float: left;

        .weeks {
          min-width: 120px;
        }
      }

      .week-workouts {
        float: right;
        margin-right: 30px;
        margin-top: 5px;
      }
    }

    &.intro .top-info {
      .title {
        float: left;
        font-size: 42px;
        font-weight: 700;

        img {
          position: relative;
          top: -3px;
        }
      }

      .btn-skip {
        float: right;
        position: relative;
        top: 2px;
        padding: 15px 22px;
        background-color: $gray;
        border: none;
        border-radius: 10px;

        /* Small devices (mobiles, 767px) */
        @media (max-width: $screen-sm-min) {
          right: 40px;
        }
      }
    }

    .middle-info {
      height: 220px;
      padding-top: 50px;
      background-color: rgba(0, 0, 0, 0.4);

      /* Small devices (mobiles, 767px) */
      @media (max-width: $screen-sm-min - 1) {
        height: 140px;
        padding-top: 10px;
      }
    }

    &.intro .middle-info {
      height: 280px;
      padding-top: 0px;
      background-color: $gray;

      .intro-slider.flickity-slider-component {
        background: transparent;

        .flickity-viewport {
          width: 100%;
          height: 280px;
        }

        .flickity-prev-next-button {
          height: 40px;
        }
      }

      /* cell inherit height from carousel */
      .tip {
        width: 100%;
        height: 100%;
      }

      .media {
        max-width: 700px;
        width: 100%;
        margin: 0 auto;
        padding: 80px 30px 0 30px;
        font-size: 20px;
        min-height: 100px;

        .media-left {
          img {
            position: relative;
            top: 20px;
            margin: 0 20px;
          }
        }

        .media-heading {
          font-size: 24px;
        }

        /* Small devices (mobiles, 767px) */
        @media (max-width: $screen-sm-min - 1) {
          font-size: 16px;

          .media-left {
            img {
              position: relative;
              top: 10px;
              margin: 0 5px;
            }
          }

          .media-heading {
            font-size: 20px;
          }
        }
      }

      /* Small devices (mobiles, 767px) */
      @media (max-width: $screen-sm-min - 1) {
        height: 200px;

        .intro-slider.flickity-slider-component {

          .flickity-viewport {
            height: 200px;
          }

          .flickity-prev-next-button {
            display: none;
          }

        }

        .media {
          padding: 20px 30px 0 30px;
        }
      }
    }

    .bottom-info {
      height: 120px;
      padding: 10px 15px;

      .btn.btn-transparent {
        border-color: $brand-primary;
        background-color: $gray;
        border-radius: 10px;
      }

      .title {
        margin: 0;
        font-size: 14px;
      }

      .videos-slider {
        margin-top: 10px;
        background-color: transparent;
        .video-card {
          position: relative;
          box-sizing: border-box;
          width: 210px;
          height: 120px;
          margin-right: 8px;
          cursor: pointer;

          .overlay {
            display: none;
          }
        }

        .flickity-prev-next-button {
          background-color: rgba(0,0,0,0.4);
        }
      }
    }

    &.intro .bottom-info {
      button {
        min-width: 280px;
        margin-top: 22px;

        &:hover, &:focus {
          background-color: $brand-primary;
        }

        &.btn-workout {
          margin-right: 0px;
        }

        @media (max-width: 600px) {
          margin-top: 2px;

          &.btn-workout {
            margin-right: 0px;
          }
        }

        // @media (min-width: 800px) and (max-width: 1300px) {
        //   margin-top: 22px;

        //   &.btn-workout {
        //     margin-right: 40px;
        //   }
        // }

        /* Small devices (tablets, 768px and up) */
        @media (min-width: $screen-sm-min) {
          margin-top: 2px;

          &.btn-workout {
            margin-right: 0px;
          }
        }

        @media (min-width: 1290px) {
          margin-top: 22px;

          &.btn-workout {
            margin-right: 40px;
          }
        }
      }
    }

    .current-moves {
      font-size: 36px;
      .current-moves-number {
        font-weight: 900;
      }
    }

    .moves-bar-component {
      margin: 0 auto;
    }

    .weekly-goal {

      .weekly-goal-number {
        color: $brand-primary;
      }
    }

    .loading-msg {
      position: relative;
      top: 60px;
    }
  }

  .workout {
    padding-left: 0px;

    .no-workout-container {
      text-align: center;
      height: 480px;
      max-width: 340px;
      margin: 0 auto;
      font-size: 30px;
      padding-top: 170px;

      /* Small devices (mobiles, 767px) */
      @media (max-width: $screen-sm-min - 1) {
        padding-top: 100px;
      }
    }
  }

  .video-thumb {
    height: $tablet-expanded-height;//calc(#{$tablet-expanded-height} - 46px);

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      height: 300px;
    }

    .image-loader {
      position: inherit !important;
    }

    .stats {
      position: absolute;
      background-color: rgba(0,0,0,0.6);
      padding: 5px 0;
      text-align: center;
      width: 100%;
      top: calc(100% - 49px);
      right: 0;
      left: 15px;
      z-index: 100;

      .stat .stat-text-top {
        font-size: 12px;
        font-weight: 300;
        color: #f1981f;
      }
    }
  }

  .upgrade-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    text-align: center;
    padding: 10px 10px 0 10px;
    z-index: 10;

    .pricing-button {
      padding: 10px 15px;
    }

    .reply-button {
      position: absolute;
      right: 10px;
      bottom: 0;
      background-color: rgba(0,0,0,0.5);
      border-radius: 10px 0 0 0;
      font-size: 24px;
    }
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    .img-content {
      transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    .img-content {
      transform: scale(1.05, 1.05);
      -ms-transform: scale(1.05, 1.05);
      -webkit-transform: scale(1.05, 1.05);
    }

    .upgrade-info {
      padding: 100px 40px 0 80px;

      .pricing-button {
        padding: 10px 30px;
      }
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    
  }

  /* Large devices (large desktops, 1400px and up) */
  @media (min-width: 1480px) {
    .img-content {
      transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
    }
  }
}