.progress-bar-component {
  .progress {
    height: 10px;
    margin-bottom: 10px;
    background-color: rgba(255,255,255,0.4);
    border-radius: 5px;

    .progress-bar {
      @include transition(width 1s ease .6s);
      &.blue {
        background-color: $brand-primary;
      }

      &.red {
        background-color: $brand-danger;
      }

      &.yellow {
        background-color: $brand-warning;
      }

      &.white {
        background-color: #e9e9e9;
      }
    }
  }
}