.tv-preview-component {
  position: fixed;
  cursor: pointer;
  border-radius: 10px;
  top: 60px;
  right: 10px;
  width: 260px;
  // height: 260px;
  background: #cc181e;
  overflow: hidden;
  z-index: 999;
  @include animation-duration(.3s);

  .content {
    padding: 20px 20px 20px 20px;
  }

  .expand-close-btn {
    top: 6px;
  }

  .expand-close-btn:after, .expand-close-btn:before {
    width: 4px;
    height: 20px;
  }

  h3 {
    margin: 0 0 10px 0;
    font-weight: 700;
    font-size: 24px;
  }

  .image-loader {
    border-radius: 4px;
  }
}