.fitnessplan-main-primary {
  position: relative;
  height: 100%;
  //background-image: url("img/fitnessplans/fitnessTopSmall.jpg");
  background-repeat: no-repeat !important;
  background-position: center center !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;

  .main-menu-slide {
    background-color: transparent;
    background: url(img/gradients/tile_overlay.png) bottom repeat-x;
  }

  .copy {
    position: absolute;
    width: calc(100% - 20px);
    left: 10px;
    bottom: 0px;
    text-align: left;

    h2 {
      font-weight: 300;
    }

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      font-size: 14px;
      h2 {
        font-size: 20px;
      }
    }
  }

  .workouts {
    .progress-bar-component {
      float: left;
      width: 10px;
      margin-right: 5px;
    }
  }
}