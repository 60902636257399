$timeElementWidth: 600px;
$selectedVideoInfoHeight: 270px;


.tv-guide-component {
  width: 100%;
  height: auto;
  outline: 1px solid transparent;
  
  #guide{
    position: relative;
    top: 0px;
    background-color: $gray-dark;
    width: 100%;
    max-height: 0;
    margin: 0 auto;
    overflow: hidden;
    @include transition(max-height .2s ease-out);

    &.isOpen {
      max-height: 232px;
      @include transition(max-height .2s ease-out);
    }
  }

  div.row.no-padding.media-mobile > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }

  #channels-list{
    padding-left: 30px;
    background: $gray;
    color: #ccc;
    
    ul{
      padding: 0;
      margin: 0;
      margin-top: 17px;
      margin-bottom: 17px;
    }
    
    li{
      list-style: none;
    }

    h3 {
      padding: 0;
      margin: 0;
    }
  }

  #main{
    position: relative;
    min-height: 170px;
    overflow-x: scroll;
    overflow-y: hidden;
    max-width: 100%;
    display: block;
    //border-right: 4px solid whitesmoke;
    //background: #292929;
  }

  .times{
    width: calc(24 * #{$timeElementWidth});
    height: 26px;
    
    > div{
      float: left;
      color: #ccc;
      background-color: #373737;
      border-right: 1px solid #ccc;
      width: $timeElementWidth;
      padding: 2px 8px;
      
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  .minutes {
    position: absolute;
    left: 0;
    top: 22px;
    width: calc(24 * #{$timeElementWidth});
    height: 26px;
    z-index: 999;

    > div{
      float: left;
      color: green;
      background-color: #000;
      border-right: 1px solid #ccc;
      width: 10px;
      padding: 0px;
      font-size: 8px;
    }
  }

  .channel-program {
    position: relative;
    width: 4800px;
    
    [class*="zone-"] {
      position: absolute;
      top: 0;
      left: 0;
    }

    .subZone-0 {
      background-color: rgba(255,255,255,0.5);
    }

    div[class*="video-"] {
      position: absolute;
      height: 129px;
      top: 0px;
      left: 0;
      padding: 8px;
      min-width: 0px !important;
      background-color: #37070A;
      border-right: 1px solid #ccc;
      overflow: hidden;
      text-overflow: ellipsis;

      @include transition(all .2s ease-out);

      &:hover, &:focus {
        background-color: #12beee;
        z-index: 9999;
        min-width: 300px !important;
        /* -ms-transform: translateX(-130px); 
        -webkit-transform: translateX(-130px); 
        transform: translateX(-130px); */
      }
    }

    .start-time small {
      font-size: 12px;
    }
  }

  .zone-0 div[class*="video-"] {
    background-color: #126D22;
  }

  .zone-1 div[class*="video-"] {
    background-color: #6D6620;
  }

  .zone-2 div[class*="video-"] {
    background-color: #1F246D;
  }

  .zone-3 div[class*="video-"] {
    background-color: #0F636D;
  }

  .zone-4 div[class*="video-"] {
    background-color: #6D3D0E;
  }

  .zone-5 div[class*="video-"] {
    background-color: #6D1368;
  }

  .zone-6 div[class*="video-"] {
    background-color: #35176D;
  }

  .subZone-0 div[class*="video-"] {
    border-bottom: solid #05FF10 4px;
  }

  .subZone-1 div[class*="video-"] {
    border-bottom: solid #FF9300 4px;
  }

  .subZone-2 div[class*="video-"] {
    border-bottom: solid #D100FF 4px;
  }

  .current-time-indicator {
    position: absolute;
    width: 2px;
    height: 155px;
    height: 100%;
    background-color: #12beee;
    top: 0;
    left: 0;
    animation: blink 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation: blink 1.5s;
    -webkit-animation-iteration-count: infinite;
  }


  h3{
    font-weight: lighter;
    padding: 16px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    span{
      //display: block;
      font-size: 18px;
      color: #989898;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .selected-video-info {
    position: relative;
    width: 100%;
    height: $selectedVideoInfoHeight;
    background-color: $gray-dark;
    max-height: 0;
    color: whitesmoke;
    padding: 0px;
    border-bottom: 4px solid #9D1218;
    overflow: hidden;
    //border-left: 4px solid whitesmoke;
    //border-right: 4px solid whitesmoke;
    @include transition(max-height .2s ease-out);

    &.isOpen {
      padding: 0px;
      max-height: $selectedVideoInfoHeight;
      @include transition(max-height .2s ease-out);
    }

    .row {
      margin: 0;
    }

    .header {
      padding: 20px !important;


    }

    .image-loader.responsive {
      height: $selectedVideoInfoHeight !important;
    }

    h3 {
      padding: 0 0 5px 0;
    }

    small {
      color: whitesmoke;
      margin-left: 20px;
    }

    .progress {
      margin: 16px 20px;
      float: left;
      width: calc(100% - 40px);
      height: 10px;
    }
  }

  .open-close-btn {
    position: relative;
    background-color: #9D1218;
    color: whitesmoke;
    width: 100px;
    height: 35px;
    line-height: 30px;
    text-align: center;
    border-radius: 0 0 10px 10px;
    margin: 0 auto;

    .fa {
      @include animate-duration(.2s);
    }
  }
}