.login-page, .register-page {
  padding-top: 30px;

  label {
    font-weight: 300;
  }

  .title {
    margin: 0 auto 10px auto;
    max-width: 480px;
  }

  .alert {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }

  .hoolio-logo > img {
    width: 121px;
    height: 33px;
  }

  #login-modal-form, #register-modal-form, .social-login {
    margin: 0px auto;
    max-width: 400px;
  }

  .btn.btn-success{
    min-width: 130px;
  }

  .forgot-pass {
    margin-top: 10px;
  }

  hr, .register hr {
    margin-top: 30px;
    margin-bottom: 0px;
    border-top: 1px $gray-light solid;
  }

  .divider, .register .divider {
    width: 60px;
    background-color: #222222;
    margin: 0 auto;
    position: relative;
    top: -20px;
    color: $gray-light;
    line-height: 30px;
    text-align: center;
    font-size: 30px;
  }

  .signup-link {
    margin-top: 20px;
  }
}