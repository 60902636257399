.explore-main-secondary-component {
  position: relative;
  height: $tablet-expanded-height;
  background: url("https://cfvod.kaltura.com/p/1968091/sp/196809100/thumbnail/entry_id/1_irot31lw/width/1920") no-repeat center center;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;

  .copy {
    z-index: 1;
    left: 10%;
    position: relative;
    margin-top: 100px;
    margin-right: 15px;
    max-width: 600px;
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    height: $mobile-expanded-height;
    text-align: center;

    .copy {
      left: auto;
      margin: 100px 15px 0 15px;
    }
  }
}