.video-thumb {
  position: relative;
  overflow: hidden;
  //max-width: 480px;

  /* .thumb {
    -webkit-animation: fadein 1s;
       -moz-animation: fadein 1s;
        -ms-animation: fadein 1s;
         -o-animation: fadein 1s;
            animation: fadein 1s;
  } */

  &.youtube-thumb{
    .img-content {
      
    }
  }

  &.scaled{
    .img-content {
      transform: scale(1.35, 1.35);
      -ms-transform: scale(1.35, 1.35);
      -webkit-transform: scale(1.35, 1.35);
    }
  }

  .overlay {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: -2px;
    z-index: 10;
    overflow: hidden;
    
    &.with-gradient {
      background: url(img/gradients/radial_dark_overlay_black.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: top left;
    }

    .play-text {
      line-height: 70px;
      position: relative;
      left: 70px;
      font-size: 24px;

      &:hover, &:focus {
        color: $brand-primary;
      }
    }

    .info-text {
      position:absolute;
      top: calc(50% + 50px);
      left: 50%;
      -ms-transform: translate(-50%,0px); /* IE 9 */
      -webkit-transform: translate(-50%,0px); /* Safari */
      transform: translate(-50%,0px);
      text-align: center;
      font-weight: 300;
      background-color: rgba(0,0,0,0.6);
      padding: 14px 16px;
      border-radius: 6px;

      .fa-angle-right {
        color: $brand-primary;
        font-size: 20px;
        margin-left: 20px;
      }

      /* Small devices (mobiles, 767px) */
      @media (max-width: $screen-sm-min - 1) {
        width: 360px;
      }
    }
  }




//, url(img/play-btn.png)
    
}
