.week-range-component {
  position: relative;
  display: inline-block;

  .week-btn {
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    @include transition(color .3s ease);

    &:hover {
      color: $brand-primary;
    }
  }

  #next-week-btn.disabled {
    opacity: .2;
    pointer-events: none;
  }

  .weeks {
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
  }

  .reset-week {
    position: relative;
    top: -10px;
    font-size: 10px;
    color: $brand-primary;

    .reset-week-btn {
      cursor: pointer;
    }
  }
}