.fitnessplan-weeks-slider {
  height: 240px;

  .flickity-slider-component {
    background: $gray-dark;
  }
  .weeks-slider {
    //background-color: $gray;
    @include boost-animation();
    .fit-slider-item {
      width: 100%;
      height: 240px;
    }

    &.flickity-slider-component .flickity-prev-next-button {
      background: $gray-dark;
      top: 35px;
      height: 40px;
    }
    
    .fit-slider-item-primary .primary-content {
      padding-bottom: 20px;

      .is-expanded {
        border: none;
      }

      .expanded-ring {
        display: none;
      }

      h5 {
        margin-bottom: 15px;
      }
    }
  }

  .fit-slider-expanded-data-container {
    padding: 0 15px !important;
  }

  .videos-slider-wrapper {
    position: relative;
    &.animated {
      @include animate-duration(1s);
    }
  }

  .videos-slider {
    .video-card {
      position: relative;
      box-sizing: border-box;
      width: 360px;
      height: 206px;
      margin-right: 8px;
    }
  }

  .fit-slider-expanded-data-container {
    padding-top: 0px;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    font-size: 44px;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .checkmark_circle {
    width:80px;
    height:80px;
    background-color: rgba(17, 189, 237, .9);
    border-radius:50%;
  }

  .fa.fa-check {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    height: 200px;

    .fit-slider-item {
      width: 100%;
      height: 200px;
    }

    .weeks-slider {
      background-color: $gray-dark;
      .fit-slider-item {
        height: 200px;
      }
    }

    .fit-slider-expanded-data-container {
      background-color: $gray-dark;
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    height: 350px;

    .fit-slider-item {
      width: 100%;
      height: 350px;
    }

    .weeks-slider {
      .fit-slider-item {
        height: 350px;
      }
    }

    .videos-slider {
      margin-top: 50px;

      .video-card {
        position: relative;
        box-sizing: border-box;
        width: 426px;
        height: 240px;
        margin-right: 8px;
      }
    }
  }
}