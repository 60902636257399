.compatibility-notification {
  display: table;
  width: 100%;
  position: relative;
  z-index: 999;
  top: 100px;
  
  .msg-container {
    position: relative;
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 40px;
    background-color: $brand-primary;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    padding: 0 30px 0 0;

    .expand-close-btn {
      top: 4px;
    }

    @media (max-width: $screen-sm-min - 1) {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.hoolio-app.head-opaque {
  .compatibility-notification {
    top: 50px;
  }
}