.fitnessplan-main-secondary {
  //margin: 0 -15px;
  background-color: $body-bg;

  .top-header {
    position: relative;
    border-radius: 0px;
    margin-bottom: 0px;
    overflow: hidden;

    img.top-header-img {
      width: 100%;
      z-index: -1;
      //min-height: 300px;
      max-height: 480px;
      min-width: 880px;

      /* Small devices (mobiles, 767px) */
      @media (max-width: $screen-sm-min - 1) {
        min-height: $mobile-expanded-height;
        min-width: 1880px;
      }

      /* Small devices (tablets, 768px and up) */
      @media (min-width: $screen-sm-min) {
        min-height: $tablet-expanded-height;
        min-width: 880px;
      }
    }

    img.top-header-people {
      position: absolute;
      bottom: 0px;
      right: 10%;
    }

    .copy {
      z-index: 1;
      top: 15%;
      left: 10%;
      width: 100%;
      position: absolute;
    }
  }

  .active-fitnessplan {
    margin: 0 15px;

    .bs-tabs {
      height: calc(#{$mobile-expanded-height} - 115px);
    }

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) {
      .bs-tabs {
        height: calc(#{$tablet-expanded-height} - 115px);
      }
    }
  }
}