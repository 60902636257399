.tile-user-component {
  position: relative;

  .tile-body {
    
  }

  .user-info-xs {
    position: absolute;
    width: 100%;
    top: 10%;
  }

  .user-info {
    position: relative;
    top: calc(50% - 100px);
  }

  .username {
    font-weight: 700;
    margin-top: 35%;
  }

  .profile-btn {
    position: absolute;
    z-index: 99;
    right: 0px;
    top: 0px;

    &:hover, &:focus {
      color: $brand-primary;
    }
  }

  .middle-info {
    min-height: auto;
  }

  .current-moves {
    font-size: 26px;
    .current-moves-number {
      font-weight: 900;
    }

    .weekly-goal {
      font-size: 14px;
    }
  }

  .moves-bar-component {
    margin: 0 auto;
  }

  .weekly-goal {

    .weekly-goal-number {
      color: $brand-primary;
    }
  }

  .loading-msg {
    position: relative;
    top: 60px;
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    .username {
      margin-top: 0;
    }

    .middle-info {
      min-height: 77px;
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    
  }

  //simple max-width query
  @media ( max-height: 800px ) and (min-width: $screen-md-min) {
    .middle-info {
      position: relative;
      top: -20px;
    }
  }
}