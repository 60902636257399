$tile_width: 16.66vmax;
$tile_width_portrait: 16.66vmin;
$tile_height: 24vmin;
$tile_wall_gutter_portrait: 15px;
$tile_wall_gutter: 100px;

.tile-wall-component {
  position: relative;
  //width: calc(100vmin - 30px);
  margin: 0 auto;
  padding-left: $tile_wall_gutter;

  .tile {
    float: left;
    margin: 0px;
    text-align: left;
    padding: 0.6vmin;
    width: 100%;
    height: $tile_height;
    min-height: 160px;

    &:hover {
      z-index: 999;
    }
    
    .tile-body {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      padding: 4px;
      //background-color: rgba(0,0,0,1);
      background-repeat: no-repeat !important;
      background-position: center center !important;
      -webkit-background-size: cover !important;
      -moz-background-size: cover !important;
      -o-background-size: cover !important;
      background-size: cover !important;
      -webkit-transition: -webkit-transform .3s ease;
      -moz-transition: -moz-transform .3s ease;
      -ms-transition: -ms-transform .3s ease;
      -o-transition: -o-transform .3s ease;
      transition: transform .3s ease;

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: transparent;
        @include transition(background-color .3s ease);
      }

      &:hover, &:focus {
        .overlay {
          background-color: rgba(18,190,238, 0);
        }

        /* .subtext {
          display: block;
        } */
      }
    }

    /* .tile-body:hover {
      -ms-transform: scale(1.03, 1.03);
      -webkit-transform: scale(1.03, 1.03);
      transform: scale(1.03, 1.03);
    } */



    .title-copy {
      z-index: 99;
    }

    .title-copy, .title-bg {
      position: absolute;
      padding: 2% 2%;
      width: 100%;
      left: 0;
      right: 0;
      top: 50%;
      transform: translate(0%, -50%);


      .title-img {
        position: relative;
        width: 30%;
        height: 30%;
        max-width: 80px;
        max-height: 80px;
        left: 50%;
        margin-bottom: 20px;
        transform: translate(-50%, 0%);
      }

      .title {
        text-align: center;
        width: 100%;
        font-weight: 300;
        font-size: 4vmin;
        margin-bottom: 0px;
      }

      .subtext {
        display: none;
        text-align: center;
        font-size: 1.2vmin;
      }
    }

    .border-line {
      display: none;
      position: absolute;
      background-color: $brand-primary;
      @include animate-duration(.3s);
    }

    .top-border {
      width: 100%;
      height: 4px;
      top: 0;
      left: 0;
      right: 0;
    }

    .bottom-border {
      width: 100%;
      height: 4px;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .left-border {
      height: 100%;
      width: 4px;
      top: 0;
      bottom: 0;
      left: 0;
    }

    .right-border {
      height: 100%;
      width: 4px;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }

  .tile.one {
    width: calc(#{$tile_width} * 3 - #{$tile_wall_gutter});
    height: calc(#{$tile_height} * 2);
  }

  .tile.two {
    width: calc(#{$tile_width} * 3 - #{$tile_wall_gutter});
    height: calc(#{$tile_height} * 2);
  }

  .tile.three {
    width: calc(#{$tile_width} * 3 - #{$tile_wall_gutter});
    height: calc(#{$tile_height} * 2);
  }

  .tile.four {
    width: calc(100vmin - (2 * #{$tile_wall_gutter}));
    height: calc(#{$tile_height} * 2);
  }

  .tile.six {
    width: calc(100vw - (2 * #{$tile_wall_gutter}));
    height: $tile_height;
  }

  .tile.two-h {
    width: calc(#{$tile_width} * 3 - #{$tile_wall_gutter});
    height: calc(#{$tile_height} * 2);
  }

  .tile.two-v {
    width: calc(#{$tile_width} * 3 - #{$tile_wall_gutter});
    height: calc(#{$tile_height} * 2);
  }

  &.is-portrait {
    width: calc(100vmin - 30px);
    padding-left: 0px;

    .tile .tile-body {
      width: 100%;
      margin-left: 0px;
    }

    .tile.one {
      width: calc(#{$tile_width_portrait} * 3 - #{$tile_wall_gutter_portrait});
      height: calc(#{$tile_height} * 2);
    }

    .tile.two {
      width: calc(#{$tile_width_portrait} * 3 - #{$tile_wall_gutter_portrait});
      height: calc(#{$tile_height} * 2);
    }

    .tile.three {
      width: calc(#{$tile_width_portrait} * 3 - #{$tile_wall_gutter_portrait});
      height: calc(#{$tile_height} * 2);
    }

    .tile.four {
      width: calc(100vmin - (2 * #{$tile_wall_gutter_portrait}));
      height: calc(#{$tile_height} * 2);
    }

    .tile.six {
      width: calc(100vw - (2 * #{$tile_wall_gutter_portrait}));
      height: $tile_height;
    }

    .tile.two-h {
      width: calc(#{$tile_width_portrait} * 3 - #{$tile_wall_gutter_portrait});
      height: calc(#{$tile_height} * 2);
    }

    .tile.two-v {
      width: calc(#{$tile_width_portrait} * 3 - #{$tile_wall_gutter_portrait});
      height: calc(#{$tile_height} * 2);
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 
    .tile .tile-body {
      &:hover, &:focus {
        .overlay {
          background-color: rgba(18,190,238, .6);
        }

        /* .subtext {
          display: block;
        } */
      }
    }
    .tile .title-copy {
      .title {
        font-weight: 300;
        font-size: 1.8vmin;
      }

      /* .subtext {
        display: block;
      } */
    }

    /* .tile.four .tile-body:hover {
      -ms-transform: scale(1.03, 1.03);
      -webkit-transform: scale(1.03, 1.03);
      transform: scale(1.03, 1.03);
    } */

    .tile.one {
      width: $tile_width;
      height: $tile_height;
    }

    .tile.two {
      width: calc(#{$tile_width} * 2 - #{$tile_wall_gutter});
      height: calc(#{$tile_height} * 2);
    }

    .tile.three {
      width: calc(#{$tile_width} * 3 - #{$tile_wall_gutter});
      height: calc(#{$tile_height} * 2);
    }

    .tile.four {
      width: calc(#{$tile_width} * 4 - #{$tile_wall_gutter});
      height: calc(#{$tile_height} * 2 - 1px);
      min-height: 320px;
    }

    .tile.two-h {
      width: calc(#{$tile_width} * 2 - #{$tile_wall_gutter});
      height: $tile_height;
    }

    .tile.two-v {
      width: calc(#{$tile_width} - #{$tile_wall_gutter});
      height: calc(#{$tile_height} * 2);
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: $screen-lg-min) { 

  }

  //simple max-width query
  @media ( max-height: 800px ) {
    .title-copy, .title-bg {
      .title {
        font-size: 3vmin !important;
      }

      .subtext {
        font-size: 2vmin !important;
      }
    }
  }
}