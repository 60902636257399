.video-chapters-component {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 101;

  .chapters-annotation {
    position: relative;
    width: 100%;
    //height: 5%;
  }

  .chapter-text {
    color: #fff;
    font-size: 2.3vw;
    font-weight: 600;
    padding: 0.5vw 1vw;
    background-color: rgba(0,0,0,0.5);
  }

  /* .prev-chapter {
    text-align: left;

    .chapter-text {
      float: left;
      font-size: 1.3vw;
      border-radius: 0 0 10px 0;
    }
  } */

  .active-chapter {
    text-align: left;

    .chapter-text {
      float: left;
      //color: $brand-primary;
      border-radius: 0 0 10px 0;
      /* animation: colorBlink 2s;
      animation-iteration-count: infinite;
      -webkit-animation: colorBlink 2s;
      -webkit-animation-iteration-count: infinite; */
    }
  }

  .next-chapter {
    text-align: right;

    .chapter-text {
      float: right;
      font-size: 1.5vw;
      border-radius: 0 0 0 10px;
    }
  }
}

