.fitnessplan-row-info {
  position: relative;
  padding: 0 15px;
  min-height: 100%;
  background-color: $gray-dark;

  .fitnessplan-video-list {
    //margin: 0 -15px;
    //padding: 0 15px;
  }

  .info-stats {
    background-color: $gray-dark;
    //margin-bottom: 10px;
    padding: 10px 0;
  }

  .info {
    border-right: 2px solid $gray;
  }

  .join-btn {
    min-width: 90px;
  }

  .search-btn {
    background-color: $gray;
    color: $brand-primary;
  }

  .tab-list-container {
    position: relative;
    padding-bottom: 2px;
    border-top: 1px solid $gray;
    

  }

  .summary {
    .summary-info {
      padding: 15px !important;
    }

    .video-thumb {
      height: calc(#{$tablet-expanded-height} - 115px);//365px;
      
      /* Small devices (mobiles, 767px) */
      @media (max-width: $screen-sm-min - 1) {
        height: 240px;
      }

      .image-loader {
        position: inherit !important;
      }
    }

    &.media-tablet {
      .video-thumb {
        height: calc(550px - 135px);//437px;
      }
    }
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    .info {
      border-right: none;
      border-bottom: 1px solid $gray;
      //padding-bottom: 10px;
      //margin-bottom: 10px;

      .actions {
        padding: 0;
        padding-top: 10px;

        .join-btn {
          width: 100%;
          border-radius: 0px;
        }
      }
    }

    .info-stats {
      margin-bottom: 0px;
    }

    .stats {
      margin-top: 10px;
    }

    .stat {
      padding: 0 15px;
      text-align: center;
      .stat-text {
        font-size: 18px;
      }
    }
  }

  .fitnessplan-completed {
    padding: 60px 15px;
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    .tab-list-container {
      position: relative;
      bottom: 0px;
      left: 0px;
      right: 0px;
    }

    .bs-tabs {
      height: calc(550px - 135px);
    }

    .stat {
      padding: 0 15px;
      max-width: 200px;
      .stat-text {
        font-size: 20px;
      }
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    .bs-tabs {
      height: calc(480px - 115px);
    }

    .stat {
      .stat-text {
        font-size: 28px;
      }
    }
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: $screen-lg-min) {
    
  }
}