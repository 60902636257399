.rotating-card-component {
  position: relative;
  width: 260px;
  height: 330px;

  /* entire container, keeps perspective */
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
  margin-top: 20px;
  margin-bottom: 20px;

  /* flip the pane when hovered */
  .card-container:not(.manual-flip):hover .card,
  .card-container.hover.manual-flip .card{
    -webkit-transform: rotateY( 180deg );
    -moz-transform: rotateY( 180deg );
    -o-transform: rotateY( 180deg );
    transform: rotateY( 180deg );
  }


  .card-container.static:hover .card,
  .card-container.static.hover .card {
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    transform: none;
  }

  /* flip speed goes here */
  .card {
    position: relative;
    -webkit-transition: -webkit-transform .5s;
    -moz-transition: -moz-transform .5s;
    -o-transition: -o-transform .5s;
    transition: transform .5s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  /* hide back of pane during swap */
  .front, .back {
    display: table;
    position: absolute;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
  }

  /* front pane, placed above back */
  .front {
    z-index: 2;
  }

  /* back, initially hidden pane */
  .back {
    -webkit-transform: rotateY( 180deg );
    -moz-transform: rotateY( 180deg );
    -o-transform: rotateY( 180deg );
    transform: rotateY( 180deg );
    z-index: 3;
  }

  /*        Style       */

  .card{
    background: none repeat scroll 0 0 #FFFFFF;
    color: #444444;
  }

  .card-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .card .cover{
    height: 250px;
    overflow: hidden;

    &.selected {
      border: 4px solid #12beee;
    }
  }
  .card .cover img{
    width: 100%;
  }
  .card .user{
    border-radius: 50%;
    display: block;
    width: 80px;
    height: 80px;
    overflow: hidden;
    margin: 0 auto;
  }
  .card .user img{
    background: none repeat scroll 0 0 #FFFFFF;
    width: 100%;
  }

  .card .content{
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    padding: 0;
    
  }
  .card .front .content {
    height: 80px;
    padding-top: 8px;
  }
  .card .back .content {
    height: 250px;
    padding-top: 0px;
    overflow: hidden;

    p {
      margin-bottom: 3px;
    }
  }
  .card .back .content .description{
    padding: 0 15px;
    margin: 0;
  }
  .card .back .content .aims, .card .back .content .loves, .card .back .content .hates{
    padding: 0 40px;
    font-size: 14px;
    i {
      margin-left: -20px;
      margin-right: 5px;
    }
  }
  .card .name {
    font-size: 22px;
    line-height: 28px;
    margin: 10px 0 0;
    text-align: center;
    text-transform: capitalize;
  }
  .card h5{
    margin: 5px 0;
    font-weight: 400;
    line-height: 20px;
  }
  .card .title{
    color: #444444;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .card .header {
    color: #999999;
    padding: 10px 20px;
    height: 80px;
    margin-bottom: 0px;
  }
  .card .motto{
    color: #999999;
    padding: 0 10px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-style: italic;
  }

  .card .select-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0;
  }

  .card .selected-text {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 44px;
    background-color: #fff !important;
    line-height: 44px;
  }

  .card .stats-container{
    /* position: absolute;
    top: 0px; */
    display: inline-block;
    width: 100%;
    background: #eaeaea;
    margin-bottom: 5px;
    
  }
  .card .stats{
    display: block;
    float: left;
    width: 33.333333%;
    text-align: center;
  }

  .card .stats:first-child{
    //border-right: 1px solid #EEEEEE;
  }
  .card .stats:last-child{
    //border-left: 1px solid #EEEEEE;
  }
  .card .stats h4{
    font-weight: 300;
    margin-bottom: 5px;
    color: #444;
  }
  .card .stats p{
    font-size: 12px;
    color: #999;
  }
}