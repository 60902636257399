.bs-dropdown-component {
   width: 160px;
   display: inline-block;
   margin: 0 10px;

  .btn.btn-primary {
    width: 100%;
    padding: 5px 10px;
    font-weight: 700;
    font-size: 20px;
  }

  ul.dropdown-menu {
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
    min-width: 60px;
  }

  li {
    text-align: center;

    &.selected {
      background-color: $brand-primary;

      a:focus {
        background-color: $brand-primary;
      }
    }
  }
}