.fit-slider {
  @include boost-animation();
  @include transition(transform .54s cubic-bezier(0.5,0,.1,1) 0s);

  .slider-title {
    color: #9C9C9C;
    font-size: 3vw;
    font-weight: 700;
  }

  .fit-slider-expanded-data-container {
    position: absolute;
    width:100%;
    height: $mobile-expanded-height;
    top: 5px;
    left: 0px;
    right: 0px;
    padding: 0;
    overflow: hidden;
    background-color: $body-bg;
  }

  .secondary-content {
    position: relative;
    color: $text-color;
    height: 100%;
    @include animate-duration(.3s);
    @include animate-delay(.2s);
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    &.is-expanded ~ .fit-slider {
      will-change: transform, -moz-transform, -webkit-transform;
      -webkit-transform: translate3d(0, $mobile-expanded-height, 0);
      -moz-transform: translate3d(0, $mobile-expanded-height, 0);
      transform: translate3d(0, $mobile-expanded-height, 0);
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    &.is-expanded ~ .fit-slider {
      will-change: transform, -moz-transform, -webkit-transform;
      -webkit-transform: translate3d(0, $tablet-expanded-height, 0);
      -moz-transform: translate3d(0, $tablet-expanded-height, 0);
      transform: translate3d(0, $tablet-expanded-height, 0);
    }

    .fit-slider-expanded-data-container {
      height: $tablet-expanded-height;
    }

    .slider-title {
      font-size: 2vw;
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    .slider-title {
      font-size: 1.4vw;
    }
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: $screen-lg-min) {
    
  }
}

