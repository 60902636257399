/************** @author: AKIS *****************/

/* ---------------------------------------------------------------------- */
/*  Chat box component
/* ---------------------------------------------------------------------- */

.chat-box {
  position:relative;
  //bottom: 0;
  //float: left;
  width: 320px;
  //margin-left: 10px;

  &.opened {
    //bottom: 0;
    //@include transition(bottom .2s ease);
  }

  &.closed {
    //bottom: -295px;
    //width: 200px;
    //@include transition(bottom .2s ease);
  }

  .row, .col-xs-12 {
    min-height: 100%;
    height: 100%;
  }

  .panel {
    border: none;
    border-radius: 0px;
    margin-bottom: 0;
    min-height: 100%;
    background-color: transparent;
  }

  .panel-heading {
    cursor: pointer;
    border-radius: 0px;
    background-color: rgba(87,68,189, 1);
    border: none;
    padding: 10px 15px 18px 15px;

    .fa-comment {
      position: relative;
      top: 2px;
      font-size: 24px;
    }

    .badge {
      position: relative;
      color: $gray;
      top: 6px;
    }

    .avatar-img {
      display: inline-block;
    }

    .unreaded-messages {
      position: absolute;
      color: #fff;
      background-color: rgba(87,68,189, 1);
      top: -10px;
      left: 2px;
      border-radius: 50%;
      padding: 5px;
      min-width: 24px;
    }

    .fa-user {
      color: rgba(87,68,189, 1);
    }

    .fa.fa-angle-up, .fa.fa-angle-down {
      font-weight: 900;
      font-size: 20px;
    }
  }

  .panel-body {
    overflow-y: auto;
    height: calc(100% - 93px);
    max-height: calc(100% - 93px);
    background-color: rgba(0,0,0,0.83);
    padding: 10px;
    position: absolute;
    bottom: 43px;
    width: 100%;
  }

  .chat {
    list-style: none;
    margin: 0;
    padding: 0;

    li.media {
      margin: 0px;
      padding-bottom: 5px;
      //border-bottom: 1px dotted #B3A9A9;
      color: rgba(255,255,255,0.5);

      &.media:last-child {
        //border-bottom: none;
        margin-bottom: 0;
      }

      .image-loader {
        margin-top: 16px;
      }
      
      .media-body {
        position: relative;

        .user-name {
          font-size: 12px;
        }

        .message {
          background-color: rgba(87,68,189, 1);//rgba(18,190,238,0.5); //$brand-primary;
          padding: 8px 10px;
          border-radius: 10px;
          color: #fff;
          float: left;
          max-width: 210px;

          white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
          white-space: -webkit-pre-wrap; /*Chrome & Safari */ 
          white-space: -pre-wrap;      /* Opera 4-6 */
          white-space: -o-pre-wrap;    /* Opera 7 */
          white-space: pre-wrap;       /* css-3 */
          word-wrap: break-word;       /* Internet Explorer 5.5+ */
          word-break: break-all;
          white-space: normal;
        }
      }

      .bot-message {
        position: relative;
        text-align: center;
        font-size: 14px;
        width: 100%;
        padding: 2px 10px;
        white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
        white-space: -webkit-pre-wrap; /*Chrome & Safari */ 
        white-space: -pre-wrap;      /* Opera 4-6 */
        white-space: -o-pre-wrap;    /* Opera 7 */
        white-space: pre-wrap;       /* css-3 */
        word-wrap: break-word;       /* Internet Explorer 5.5+ */
        word-break: break-all;
        white-space: normal;

        .image-loader {
          display: inline-block;
          vertical-align:top;
          margin: 0 4px 0 0;
        }
      }
    }
  }

  .panel-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 0px;

    input {
      outline: none;
      border: none;
      border-radius: 0px;
    }
  }

  .jumping-dots span {
    position: relative;
    bottom: 0px;
    -webkit-animation: jump 1500ms infinite;
    animation: jump 2s infinite;
    font-weight: 700;
  }
  .jumping-dots .dot-1{
    -webkit-animation-delay: 200ms;
    animation-delay: 200ms;
  }
  .jumping-dots .dot-2{
    -webkit-animation-delay: 400ms;
    animation-delay: 400ms;
  }
  .jumping-dots .dot-3{
    -webkit-animation-delay: 600ms;
    animation-delay: 600ms;
  }
}

@-webkit-keyframes jump {
  0%   {bottom: 0px;}
  20%  {bottom: 5px;}
  40%  {bottom: 0px;}
}

@keyframes jump {
  0%   {bottom: 0px;}
  20%  {bottom: 5px;}
  40%  {bottom: 0px;}
}
