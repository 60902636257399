.tile-user-row-component.tile.six {
  height: 120px;
  min-height: 120px;

  &.is-portrait {
    height: 120px;
    min-height: 120px;
  }

  .tile-body {
    position: relative;
    //padding: 20px 0;
    //background-color: rgba(0,0,0,0.5);
  }

  .stats-bar {
    position: absolute;
    width: calc(100% - 50px);
    height: 80px;
    top: 10px;
    margin-left: 50px;
    padding-left: 70px;
    background-color: $gray;
  }

  .user-name {
    width: 100%;
    float: left;
    padding-top: 18px;
    
    h3 {
      margin: 0;
      font-size: 4vmin;
      font-weight: 700;
    }
  }

  .stats-data {
    width: 75%;
    float: left;
    padding-top: 22px;
  }

  .stat {
    text-align: center;
    
    img {
      width: 26px;
      height: 26px;
    }

    h5 {
      margin: 0;
      font-weight: 700;
      font-size: 12px;
    }

    .week-range-component {
      position: relative;
      top: 0px;

      .weeks, .week-btn {
        font-size: 14px;
      }

      .week-btn {
        padding: 5px;
      }
    }

    .media-body {
      text-align: left;
    }
  }

  .avatar {
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0px;
    width: 100px;
    height: 100px;
  }

  .avatar-img {
    width: 100px;
    height: 100px !important;
    margin: 0px auto 0 auto;

    .img-content {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 4px solid $brand-primary;
      background-color: $brand-primary;
    }
  }

  .premium-badge {
    position: absolute;
    top: 65px;
    left: 72px;
    margin: 0 auto;

    .diamond {
      position: absolute;
      top: 10px;
      left: 8px;
    }

    .circle {
      position: relative;
      width: 30px; 
      height: 30px;
      border-radius: 50%;
      background: rgba(0,0,0,1);
    }
    .arc {
      overflow: hidden;
      position: absolute;
      top: -4px; 
      right: 50%; 
      bottom: 50%; 
      left: -4px;
      transform-origin: 100% 100%;
    }
    .arc:before {
      box-sizing: border-box;
      display: block;
      border: solid 4px $brand-primary;
      width: 200%; 
      height: 200%;
      border-radius: 50%;
      transform: skewX(-30deg);
      content: '';
    }

    .arc1 {
      transform: rotate(120deg) skewX(30deg);
    }

    .arc2 {
      transform: rotate(180deg) skewX(30deg);
    }

    .arc3 {
      transform: rotate(225deg) skewX(30deg);
    }

    .arc4 {
      transform: rotate(260deg) skewX(30deg);
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 
    .user-name {
     width: 25%;
      h3 {
        font-size: 1.8vmin;
      }
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    .stat {
      text-align: center;

      img {
        width: 36px;
        height: 36px;
      }

      h5 {
        margin: 0;
        font-weight: 700;
        font-size: 16px;
      }

      .week-range-component {
        position: relative;
        top: -8px;

        .weeks, .prev-week-btn, .next-week-btn {
          font-size: 16px;
        }

        .week-btn {
          padding: 10px;
        }
      }

      .media-body {
        text-align: left;
      }
    }
  }
}