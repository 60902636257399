/************** @author: AKIS *****************/

// Font
@import url(https://fonts.googleapis.com/css?family=Lato:900,700,300);

// Core variables and mixins
@import "variables-overrides";
@import "../bootstrap/mixins";

// Reset and dependencies
@import "../bootstrap/normalize";
@import "../bootstrap/print";

// Core CSS
@import "../bootstrap/scaffolding";
@import "../bootstrap/type";
@import "../bootstrap/code";
@import "../bootstrap/grid";
@import "../bootstrap/tables";
@import "../bootstrap/forms";
@import "../bootstrap/buttons";

// Components
@import "../bootstrap/component-animations";
@import "../bootstrap/dropdowns";
@import "../bootstrap/button-groups";
@import "../bootstrap/input-groups";
@import "../bootstrap/navs";
@import "../bootstrap/navbar";
//@import "../bootstrap/breadcrumbs";
//@import "../bootstrap/pagination";
//@import "../bootstrap/pager";
@import "../bootstrap/labels";
@import "../bootstrap/badges";
@import "../bootstrap/jumbotron";
@import "../bootstrap/thumbnails";
@import "../bootstrap/alerts";
@import "../bootstrap/progress-bars";
@import "../bootstrap/media";
@import "../bootstrap/list-group";
@import "../bootstrap/panels";
@import "../bootstrap/responsive-embed";
@import "../bootstrap/wells";
@import "../bootstrap/close";

// Components w/ JavaScript
@import "../bootstrap/modals";
//@import "../bootstrap/tooltip";
//@import "../bootstrap/popovers";
//@import "../bootstrap/carousel";

// Utility classes
@import "../bootstrap/utilities";
@import "../bootstrap/responsive-utilities";

.left-inner-addon {
    position: relative;
}
.left-inner-addon input {
    padding-left: 30px;    
}
.left-inner-addon i {
    position: absolute;
    padding: 10px 12px;
    pointer-events: none;
}

.btn {
  border-radius: 3px;
}