.webrtc-container {
  position: relative;
  overflow:hidden;

  .OT_publisher, .OT_subscriber {
    @include transition(all 0.4s ease);
  }
  
  &.horizontal-layout {
    top:50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    .OT_publisher, .OT_subscriber {
      width: 100% !important;
    }

    &.one-user {
    
    }

    &.two-users {
      &.youtube {
        top: -$workout-navbar-height;
        -ms-transform: none;
        -webkit-transform: none;
        transform: none;
      }
    }

    &.three-users {
      &.youtube {
        top: -$workout-navbar-height;
        -ms-transform: none;
        -webkit-transform: none;
        transform: none;
      }
    }
  }

  &.vertical-layout {
    top: 0px;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;

    .OT_publisher, .OT_subscriber {
      margin: 0 auto;
      float: left;
    }

    &.one-user {
      .OT_publisher, .OT_subscriber {
        float: none;
      }
    }

    &.two-users {
      &.youtube {
        top: 0px;
      -ms-transform: none;
      -webkit-transform: none;
      transform: none;
      }
    }

    &.three-users {
      &.youtube {
        top: 0px;
        -ms-transform: none;
        -webkit-transform: none;
        transform: none;
      }
    }
  }

  

  .OT_subscriber {
    .OT_widget-container {
      -ms-transform: scale(-1, 1);
      -webkit-transform: scale(-1, 1);
      transform: scale(-1, 1);
    }
  }
}