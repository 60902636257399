.studios-page.fitnessplans-page  {
  .top-header {
    background: url("../assets/img/studios/studios_header.jpg") no-repeat center center;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    height: 313px;

    .col-xs-12 {
      height: 313px;
    }

    .copy {
      top: 20%;
      width: calc(100% - 30px);
      left: 15px;

      img {
        margin: 0 auto 10px auto;
      }
    }
  }
  .fit-slider-item-primary .primary-content .expanded-ring {
    border-color: transparent;//rgba(87,68,189, 1);
    //background-color: rgba(87,68,189, .4);
  }

  .slider-title {
    font-size: 20px;
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 
    
    .top-header { 
      height: 480px;

      .col-xs-12 {
        height: 480px;
      }

      .copy {
        top: 30%;
        width: 90%;
        left: 10%;

        img {
          margin: 0 0 10px 0;
        }
      }
    }

    .slider-title {
      font-size: 28px;
    }
  }
}