.search-page {
  padding-top: 50px;
  
  .results {
    min-height: 770px;

    &.isMobile {
      min-height: 380px;
    }
  }

  .no-results {
    margin-top: 50px;
    color: $gray-light;
    font-weight: 700;
  }

  .fa.fa-times {
    cursor: pointer;
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    padding-top: 100px;
  }
}