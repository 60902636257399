.video-card {
  @include transition(opacity .3s ease);

  &.fadeout-effect {
    @include opacity(.5);
  }

  .hidden {
    display: none;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background: url(img/gradients/tile_overlay.png) bottom repeat-x;
  }
  
  .card {
    position: relative;
    height: 100%;
    background-color: $gray-dark;
  }

  .image-loader {
    min-height: 100%;
  }

  /* .card-title {
    position: relative;
    float: left;
    left: 8px;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    
  }

  .card-subtitle {
    position: relative;
    bottom: 0px;
    width: 85%;
    padding: 8px 0px;
    font-weight: 300;
    font-size: 12px;
  }

  .card-subtitle {
    float: left;
    background-color: $gray;
    width: 85%;
  } */

  .video-badge {
    position: absolute;
    width: 30px;
    min-height: 15px;
    line-height: 17px;
    right: 6px;
    bottom: 6px;
    font-weight: 300;
    font-size: 12px;

    &.badge-free {
      width: 30px;
    }

    &.badge-premium {
      width: 15px;
    }
  }

  .top-premium-badge  {
    position: absolute;
    top: 6px;
    right: 6px;
  }

  &.loading {
    background-color: $gray-dark;

    .overlay {
      background: rgba(0,0,0,0.6);
    }
  }

  .hoolio-play-btn {
    -webkit-animation-timing-function: ease-in-out; /* Chrome, Safari, Opera */
    animation-timing-function: ease-in-out;
    @include animation-delay(0s);
    
    &.fadeIn {
      @include animation-duration(.4s);
    }

    &.fadeOut {
      @include animation-duration(.4s);
    }
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {

    .video-badge {
      top: 0;
      color: $brand-primary;
      top: 6px;
      right: 4px;
      font-size: 10px;
      font-weight: 700;
    }

    .top-premium-badge  {
      top: 2px;

      .retina-image {
        height: 16px;
        width: 14px;
      }
    }
  }
}