.explore-main-primary {
  position: relative;
  height: 100%;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;

  .main-menu-slide {
    background-color: transparent;
  }

  .copy {
    position: absolute;
    width: 100%;
    left: 10px;
    bottom: 0px;
    text-align: left;

    h2 {
      font-weight: 300;
    }

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      font-size: 14px;
      h2 {
        font-size: 20px;
      }
    }
  }
}