.player-container {
  position: relative;
  top:50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  &.vertical-layout.publisher-connected {
    top:0;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;

    .youtube-player, .kaltura-player {
      top:50%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  &.youtube {
    margin: 0 auto;
    width: 100%;

    .youtube-player {
      margin: 0 auto;
    }
  }

  &.kaltura {
    margin: 0 auto;
    width: 100%;

    .kaltura-player {
      margin: 0 auto;
    }
  }

  .player-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 102;
  }
}