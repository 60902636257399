.moves-bar-component {
  position: relative;

  &.is-full {
    /* #moves-boxes path{
      fill: $brand-success; 
    } */
  }

  .moves-progress {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 10%;
    overflow: hidden;
    @include transition(width 1s ease .6s);

    /* &:hover {
      width: 100%;
    } */
  }

  #moves-bg path {
    fill: rgba(255,255,255,0.4);
  }

  #moves-boxes path{
    fill: $brand-primary; 
  }
}