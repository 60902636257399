.bs-tab-list {
  z-index: 999;
  background-color: $gray-dark;
  
  &.nav-tabs {
    border: none;
  }

  &.nav-tabs > li{
    float:none;
    display:inline-block;
    min-width: 110px;
    box-sizing: border-box;
  }

  &.nav-tabs {
    text-align:center;
  }

  &.nav > li > a:hover, &.nav > li > a:focus {
    text-decoration: none;
    background-color: transparent;
    box-sizing: border-box;
  }

  &.nav-tabs > li > a {
    color: $text-color;
    border: none;
    border-radius: 0;
    border-bottom-color: transparent;
    border-bottom: 2px solid;
    border-bottom-color: rgba(20, 212, 255, 0);
    box-sizing: border-box;
    @include transition(border-bottom-color .3s ease);
  }

  &.nav-tabs > li.active > a, &.nav-tabs > li.active > a:hover, &.nav-tabs > li.active > a:focus {
    color: $text-color;
    background-color: transparent;
    border: none;
    border-bottom-color: transparent;
    border-bottom: 2px solid;
    border-bottom-color: rgba(20, 212, 255, 0);
    @include transition(border-bottom-color .3s ease);
  }

  &.nav-tabs > li.active > a, &.nav-tabs > li > a:hover, &.nav-tabs > li > a:focus {
    border-bottom-color: rgba(20, 212, 255, 1) !important;
  }
}
