.image-uploader-component {

}

.ajax-upload-dragdrop {
  padding: 40px 20px 40px 20px;
  width: 100% !important;
  border: 2px dashed #545454;
  margin: 5px;
  .btn {
    margin-right: 20px
  }

  span {
    color: #f37135;
  }
}

.ajax-file-upload-statusbar {
  border-color: #9474b4;
  width: 100% !important;
}

.ajax-file-upload-error {
  display: none;
  color: #f37135;
}

.ajax-file-upload-bar {
  background-color: $brand-primary;
}