.activate-page {
  padding-top: 50px;

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    padding-top: 100px;
  }
  
  .loader {
    margin: 2em auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgb(66, 65, 67);
    border-right: 1.1em solid rgb(66, 65, 67);
    border-bottom: 1.1em solid rgb(66, 65, 67);
    border-left: 1.1em solid #12beee;
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}