.navbar {
  margin-bottom: 0px;

  .container-fluid {
    text-align: center;
  }
}

.navbar-brand {
  position: relative;
  padding-top: 10px;
  padding-bottom: 0px;
  
  img {
    width: 121px;
    height: 33px;
  }
}

.navbar-inverse {
  height: 100px;
  background-color: transparent;
  background-image: -webkit-gradient(linear,left top,left bottom,color-stop(10%,rgba(0,0,0,.7)),to(rgba(0,0,0,0)));
  background-image: -webkit-linear-gradient(top,rgba(0,0,0,.7) 10%,rgba(0,0,0,0));
  background-image: -moz-linear-gradient(top,rgba(0,0,0,.7) 10%,rgba(0,0,0,0));
  background-image: -o-linear-gradient(top,rgba(0,0,0,.7) 10%,rgba(0,0,0,0));
  background-image: linear-gradient(to bottom,rgba(0,0,0,.7) 10%,rgba(0,0,0,0));
  @include transition(all .4s ease);

  > .container-fluid {
    margin-top: 25px;
    @include transition(all .4s ease);
  }

  .navbar-right .dropdown.user-item {
    @include transition(all .4s ease);
    background-color: transparent;
    color: #fff;
  }

  .navbar-right .dropdown.user-item:hover, .navbar-right .dropdown.user-item:focus {
    background-color: transparent;
    color: #12beee;
  }

  .navbar-right .dropdown.user-item a.dropdown-toggle {
    color: #fff;
  }

  .navbar-right .dropdown.user-item .fa.fa-angle-down {
    display: none;
  }

  .navbar-header {
    margin-left: 50px;
    @include transition(all .4s ease);
  }

  .navbar-left {
    margin-left: 100px;
    @include transition(all .4s ease);
  }

  .navbar-right {
    margin-right: 50px;
    @include transition(all .4s ease);
  }

  .navbar-right .dropdown.user-item.open > a, .navbar-right .dropdown.user-item.open > a:hover, .navbar-right .dropdown.user-item{
      min-width: 160px;

     &.open > a:focus {
        border-radius: 8px 8px 0 0;
        
      }
  }

  .navbar-right .dropdown.user-item, .navbar-right .dropdown.user-item .dropdown-menu {
    border-radius: 0 0 8px 8px;
  }

  .search-form .form-group span.form-control-feedback {
    font-size: 28px;
    @include transition(all .4s ease);
  }

  .navbar-nav.navbar-left li a {
    font-weight: 300;
    font-size: 24px;
    @include transition(all .4s ease);
  }

  .navbar-nav > li.login-btn > a {
    color: #fff;
  }

  ::-webkit-input-placeholder {
   color: #fff;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: #fff;  
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    color: #fff;  
  }

  :-ms-input-placeholder {  
    color: #fff;  
  }
}

.navbar-inverse.head-opaque {
  height: 50px;
  background-color: rgba(18, 18, 18, 0.9);
  border-color: rgba(18, 18, 18, 0);

  > .container-fluid {
    margin-top: 0px;
  }

  .navbar-right .dropdown.user-item {
    background-color: #fff;
    color: inherit;
  }

  .navbar-right .dropdown.user-item:hover, .navbar-right .dropdown.user-item:focus {
    background-color: #12beee;
    color: #fff;
  }

  .navbar-right .dropdown.user-item a.dropdown-toggle {
    color: #000;
  }

  .navbar-right .dropdown.user-item .fa.fa-angle-down {
    display: block;
  }

  .navbar-header {
    margin-left: 0px;
  }

  .navbar-left {
    margin-left: -15px;
  }

  .navbar-right {
    margin-right: -15px;
  }

  .navbar-right .dropdown.user-item.open > a, .navbar-right .dropdown.user-item.open > a:hover, .navbar-right .dropdown.user-item.open > a:focus {
    border-radius: 0 0 0 8px;
  }

  .navbar-right .dropdown.user-item, .navbar-right .dropdown.user-item .dropdown-menu {
    border-radius: 8px;
  }

  .search-form .form-group span.form-control-feedback {
    font-size: 20px;
  }

  .navbar-nav.navbar-left li a {
    font-weight: 300;
    font-size: initial;
  }

  .navbar-nav > li.login-btn > a {
    color: #9d9d9d;
  }

  ::-webkit-input-placeholder {
   color: #9d9d9d;
  }

  :-moz-placeholder { /* Firefox 18- */
    color: #9d9d9d;  
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    color: #9d9d9d;  
  }

  :-ms-input-placeholder {  
    color: #9d9d9d;  
  }
}

.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
  background-color: transparent;
}

/* Small devices (mobiles, 767px) */
@media (max-width: $screen-sm-min - 1) {
  .navbar-inverse .navbar-nav > li > a {
    color: #000 !important;

    &:hover, &:focus {
      color: $brand-primary;
    }
  }
}


.navbar-fixed-top {
  border-width: 0;

  .fullscreen-btn {
    float: right;
    height: 50px;
    margin-right: 20px;
  }
}

.trial-bar {
  display: inline;
  line-height: 50px;

  .btn {
    padding: 4px 12px;
    border: 1px solid #fff;

    &:hover {
      background-color: $brand-primary;
      border-color: $brand-primary;
    }
  }
}

.collapse.navbar-collapse {

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 
    float: right;
  }
}

.navbar-inverse.head-opaque .navbar-nav.navbar-left {
  @media (min-width: $screen-sm-min) { 
    margin-left: 0;
  }
}

.navbar-inverse .navbar-nav.navbar-left {
  position: relative;
  left: auto;
  text-align: left;
  background: #fff;
  margin-top: 0;
  margin-bottom: 0;
  
  li a {
    @include transition(color .3s ease);

    &.active {
      color: $brand-primary;
    }
  }

  li:hover {
    a {
      color: #fff !important;
    }
  }

  @media (min-width: $screen-sm-min) { 
    position: absolute;
    left: 140px;
    text-align: center;
    background: transparent;
    margin-top: inherit;
    margin-bottom: inherit;
  }
}



.navbar-right {
  text-align: left;

  .signup-btn {
    padding-top: 5px;
    padding-right: 10px;

    .visible-xs-block {
      color: #000;

      &:hover, &:focus {
        color: $brand-info;
      }
    }
    
    .btn {
      padding: 7px 20px;
      border: 2px solid #12beee;
      border-radius: 6px;
      border-color: $brand-primary;
      color: #fff;

      &:hover {
        background-color: $brand-primary;

        small {
          color: #fff;
        }
      }
    }

    small {
      position:absolute;
      right: 16px;
      bottom: -4px;
      font-size: 8px;
      color: $brand-primary;
      @include transition(color .3s ease);
    }
  }

  /* .signup-btn {
    .btn.btn-success {
      min-width: 150px;
      color: #fff;
      border-radius: 0 0 0 8px;
      border: none;
      @include transition(color 0.3s ease);
      @include transition(background-color 0.3s ease);

      &.active {
        display: none;
      }

      &:hover {
        background-color: #fff;
        color: $gray;
      }

      @media (max-width: $screen-sm-min - 1) {
        border-radius: 0;
      }
    }
  } */

  .dropdown.user-item {
    background-color: #fff;
    color: inherit;
    border-radius: 0 0 0 8px;
    @include transition(background-color 0.3s ease);

    &:hover, &:focus {
      background-color: $brand-info;
      color: #fff;
    }

    .dropdown-menu {
      margin-top: 0px;
      margin-right: 0px;
      border-radius: 8px;
      left: 0;
      right: 0px;

      > li > a {
        color: #000;
        @include transition(color 0.3s ease);
      }

      > li > a:hover, > li > a:focus {
        text-decoration: none;
        color: $brand-info;
        background-color: #fff;
      }

      /* Small devices (mobiles, 767px) */
      @media (max-width: $screen-sm-min - 1) {
        border-radius: 0;
        background-color: #fff;

        > li > a:hover, > li > a:focus {
          color: $brand-info;
          background-color: #fff;
        }
      }
    }

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      border-radius: 0;
      background-color: inherit;
    }

    a.dropdown-toggle {
      position: relative;
      height: 50px;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-right: 0px;
      color: #000;
      @include transition(color 0.3s ease);

      /* Small devices (mobiles, 767px) */
      @media (max-width: $screen-sm-min - 1) {
        color: #000;
      }
    }

    .user-name {
      float: left;
      margin-top: 14px;
      margin-right: 30px;
    }

    .fa.fa-angle-down {
      float: right;
      height: 100%;
      background-color: $gray;
      padding: 14px 16px;
      color: #fff;
      font-size: 24px;
      @include transition(color 0.3s ease);
    }

    & > a:hover {
      color: #fff;

      .fa.fa-angle-down {
        color: $brand-info;
      }
    }

    &.open > a,  &.open > a:hover,  &.open > a:focus {
      background-color: $brand-info;
      border-radius: 0 0 0 8px;
      color: #fff;

      /* Small devices (mobiles, 767px) */
      @media (max-width: $screen-sm-min - 1) {
        border-radius: 0;
      }
    
      .fa.fa-angle-down {
        color: $brand-info;
      }
    }
  }

  &.isMobile > li > a, &.isMobile > li > a:hover, &.isMobile > li > a:focus {
    color: #000;
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    margin-top: 0;
    margin-bottom: 0;
    background-color: #fff;
  }
}

.profile-image {
  padding-left: 15px;

  .avatar-img {
    position: relative;
    top: 9px;
    float: left;
    left: -10px;

    .circle-spinner {
      border-color: $brand-primary;
      border-right-color: transparent;
    }
  }
}

.main-outlet.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

// Global platform notifications styles
.global-flashes-container {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  top: 10px;
  z-index: 1100;

  .alert {
    text-align: center;
    min-width: 250px;
    max-width: 600px;
    -webkit-box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.80);
    -moz-box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.80);
    box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.80);
    background: rgba(0,0,0,0.66);
    border: 2px solid #282828;
    border-radius: 8px;
    @include animate-duration(1s);

    p {
      font-weight: 700;
    }
  }

  .alert-success {
    color: $brand-primary;
  }
}

.skip-btn {
  position: fixed;
  top: 0;
  right: 0;
  //margin-right: 20px;
  font-size: 24px;
  font-weight: 300;
  padding: 8px 15px 0 15px;
  color: $gray-light;
  width: 80px;
}

.bottom-info {
  height: 500px;
}

.mobile-search-btn {
  position: relative;
  top: 10px;
  right: -40px;
  color: #fff;
  font-size: 22px;
}

.search-form .form-group {
  float: right !important;
  transition: all 0.35s, border-radius 0s;
  width: 32px;
  height: 32px;
  top: 9px;
  right: 20px;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid rgba(255,255,255,0);
  color: #fff;
  @include transition(all .4s ease);

  &.isMobile {
    float: none !important;
    margin: 20px auto;
    top: inherit;
    right: inherit;
  }
}
.search-form .form-group input.form-control {
  padding: 6px 20px 6px 12px;
  border: 0 none;
  background: transparent;
  box-shadow: none;
  display:block;
  color: #fff;
  z-index: 1;

  &.isMobile {
    padding: 6px 12px;
  }
}

.search-form .form-group:hover,
.search-form .form-group:focus,
.search-form .form-group.hover {
  width: 260px;
  border-bottom: 1px solid rgba(255,255,255,1);
}
.search-form .form-group span.form-control-feedback {
  position: absolute;
  top: -1px;
  right: -2px;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  color: #fff;
  left: initial;
  font-size: 20px;

  &.clear-btn {
    pointer-events: inherit;
    cursor: pointer;
  }
}


.c-hamburger {
  display: block;
  position: relative;
  overflow: hidden;
  top: 8px;
  right: 10px;
  margin: 0;
  padding: 0;
  width: 38px;
  height: 38px;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
}

.c-hamburger:focus {
  outline: none;
}

.c-hamburger span {
  display: block;
  position: absolute;
  top: 18px;
  left: 4px;
  right: 4px;
  height: 2px;
  background: white;
}

.c-hamburger span::before,
.c-hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  content: "";
}

.c-hamburger span::before {
  top: -8px;
}

.c-hamburger span::after {
  bottom: -8px;
}

.c-hamburger--htx {
  //background-color: #ff3264;
}

.c-hamburger--htx span {
  @include transition(background 0s 0.3s);
}

.c-hamburger--htx span::before,
.c-hamburger--htx span::after {
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
}

.c-hamburger--htx span::before {
  transition-property: top, transform;
}

.c-hamburger--htx span::after {
  transition-property: bottom, transform;
}

/* active state, i.e. menu open */
.c-hamburger--htx[aria-expanded="true"] {
  //background-color: #cb0032;
}

.c-hamburger--htx[aria-expanded="true"] span {
  background: none;
}

.c-hamburger--htx[aria-expanded="true"] span::before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.c-hamburger--htx[aria-expanded="true"] span::after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.c-hamburger--htx[aria-expanded="true"] span::before,
.c-hamburger--htx[aria-expanded="true"] span::after {
  transition-delay: 0s, 0.3s;
}