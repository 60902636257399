.onboarding-page {
  position: fixed;
  height: calc(100% - 80px);
  width: 100%;
  top: 50px;
  overflow: hidden;
  
  #selected-videos {
    position: absolute;
    width: 100%;
    height: 100%;

    .top-layer {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.9);
    }
    
    .image-tile {
      margin: 20px;
    }
  }
  
  .next-btn {
    font-size: 24px;
    font-weight: 700;
    width: 300px;
    margin-top: 30px;

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) { 
      margin-top: 50px;
    }

    @media ( max-height: 768px ){
      margin-top: 10px;
    }
  }

  .user-name {
    margin-top: 0px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 300;

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) { 
      margin-top: 20px;
      font-size: 30px;
    }

    .name {
      font-weight: 900;
    }
  }

  .rainbow-line {
    position: relative;
    margin: 10px auto 18px auto;
    width: 370px;

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) { 
      width: 400px;
    }

    .line {
      display:block;
      border:none;
      height:2px;
      background-image: -webkit-linear-gradient(-134deg, #EA8B30 0%, #11BEEE 100%);
      background-image: -moz-linear-gradient(-134deg, #EA8B30 0%, #11BEEE 100%);
      background-image: -o-linear-gradient(-134deg, #EA8B30 0%, #11BEEE 100%);
      background-image: -ms-linear-gradient(-134deg, #EA8B30 0%, #11BEEE 100%);
      background-image: linear-gradient(-134deg, #EA8B30 0%, #11BEEE 100%);
    }
  }

  .static-banner {
    position: absolute;
    bottom: 60px;
    width: 100%;
    text-align: center;
    font-weight: 300;
    color: #888;

    @media ( max-height: 768px ){
      bottom: 0px;
      font-size: 14px;
    }
  }

  .basic-info, .instincts, .user-level {
    position: relative;
    padding: 0 15px;
    top: 0%;
  }

  .basic-info {
    @media ( max-height: 768px ){
      top: 10%;
    }

    @media ( min-height: 900px ){
      top: 15%;
    }
    
    .avatar-img {
      margin: 20px auto 0 auto;

      .img-content {
        border-radius: 50%;
        border: 4px solid #fff;
      }
    }

    .user-photo {
      position: relative;
    }

    .actions {
      position: absolute;
      text-align: center;
      bottom: -20px;
      @include calc(right, "50% - 80px");

      .fa {
        padding: 5px;
        cursor: pointer;
        font-size: 18px;
        color: rgba(255,255,255,0.7);
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 1);

        &:hover, &:focus {
          color: $brand-primary;
        }
      }

      .ajax-file-upload-statusbar {
        position: absolute;
        left: -98px;
        top: 25px;
        height: 5px;
        border: none;
        margin: 0 auto;
        max-width: 126px;
        width: 126px;
        padding: 0;

        .ajax-file-upload-progress {
          margin: 0px !important;
          position: relative;
          width: 126px;
          border: none;
          padding: 0px;
          border-radius: 2px;

          .ajax-file-upload-bar {
            height: 6px;
            border-radius: 1px;
          }
        }

        .ajax-file-upload-filename, .ajax-file-upload-abort, .ajax-file-upload-cancel, .ajax-file-upload-green, .ajax-file-upload-red {
          display: none !important;
        }
      }

      .change-profile-pic {
        &:before {
          cursor: pointer !important;
        }
        cursor: pointer !important;
      }
    }

    .user-data {
      font-size: 18px;
      margin-top: 10px;

      /* Small devices (tablets, 768px and up) */
      @media (min-width: $screen-sm-min) { 
        font-size: 26px;
      }

      .bs-dropdown-component#age-dropdown {
        width: 70px;
      }
    }
  }

  .instincts {
    .user-name {
      margin-top: 0;
    }

    .rainbow-line {
      margin: 10px auto 0px auto;
      width: 100%;
    }

    .help-text {
      font-size: 18px;

      /* Small devices (tablets, 768px and up) */
      @media (min-width: $screen-sm-min) { 
        font-size: 24px;
      }
    }

    .slider-cell {
      margin-right: 8px;
    }

    @media ( min-height: 900px ){
      top: 5%;
    }
  }

  .user-level {
    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) { 
      top: 15%;
    }

    .fit-info {
      margin: 30px 0;
      font-size: 26px;

      .fit-value {
        border-bottom: 2px solid $brand-primary;
      }
    }

    .fit-slider {
      color: #fff;
      font-size: 18px;
      line-height: 25px;
    }

    .slider-input {
      position: relative;
      top: -2px;
      display: inline-block;
      margin: 0 20px;
      width: 260px;

      /* Small devices (tablets, 768px and up) */
      @media (min-width: $screen-sm-min) { 
        width: 300px;
      }
    }
  }
}