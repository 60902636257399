.fitnessplans-page {
  //padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;

  .top-header {
    position: relative;
    border-radius: 0px;
    margin-bottom: 0px;
    overflow: hidden;
    min-height: 300px;
    max-height: 480px;
    

    img.top-header-img {
      width: 100%;
      z-index: -1;
      //min-height: 300px;
      max-height: 480px;
      min-width: 880px;
    }

    img.top-header-people {
      position: absolute;
      bottom: 0px;
      right: 10%;
    }

    .copy {
      z-index: 1;
      top: 25%;
      left: 10%;
      width: 100%;
      position: absolute;
    }

    .slider-title {
      color: $text-color;
      position: absolute;
      //margin-bottom: 4px;
      bottom: 0px;
      left: 15px;
    }
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    padding-bottom: 70px;

    .top-header .copy {
      /* h1 {
        font-size: 32px;
        font-weight: 700;
      } */
    }

    .video-thumb {
      margin: 0 auto;
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    //padding-top: 100px;

    .top-header .copy {
      //top: 50px;
      /* h1 {
        font-size: 42px;
        font-weight: 700;
      } */
    }

    .fit-slider-container.is-expanded {
      padding-bottom: 534px;
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    .top-header .copy {
      //top: 80px;
      /* h1 {
        font-size: 52px;
        font-weight: 700;
      } */
    }
  }

  .btn.join-btn.fixed-bottom {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    z-index: 999;
    border-radius: 0px;
  }

  .fitnessplans.fit-slider {
    margin-top: 5px;

    .fit-slider-expanded-data-container {
      padding: 0;
    }
  }
}

h1.fitnessplan-header-copy-h1 {
  font-size: 52px;
  font-weight: 900;
}

@media (max-width: $screen-sm-min - 1) {
  h1.fitnessplan-header-copy-h1 {
    font-size: 32px;
    font-weight: 700;
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) {
  h1.fitnessplan-header-copy-h1 {
    font-size: 42px;
    font-weight: 900;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  h1.fitnessplan-header-copy-h1 {
    font-size: 52px;
    font-weight: 800;
  }
}