/************** @author: Akis Volanis *****************/

/* Bootstrap */
@import "./bootstrap/custom";

/* Functions */
@import "functions";

/* Mixins */
@import "./mixins/animation";
@import "./mixins/keyframes";
@import "./mixins/opacity";
@import "./mixins/animate-delay";
@import "./mixins/animate-duration";
@import "./mixins/animate-iteration-count";
@import "./mixins/transition";
@import "./mixins/calc";
@import "./mixins/at2x";
@import "./mixins/aspect-ratio";
@import "./mixins/boost-animation";

@import "./general/utilities";
@import "./general/hoolio-loader";

@import "pods";

/* 
 *  General app scss
 *
 */

html { 
  font-size: 100%; 
  height: 100%;
  background-color: $body-bg;
}

body {
  position: relative;
  margin: 0;
  height: 100%;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on;

  /* &.modal-open .main-outlet {
    filter: blur(30px);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
  } */

  &.blurred-bg {
    background-color: transparent !important;
  }
}

* {
  outline: none;
  transition: none;
}

.j-youtube, .youtube {
  background: transparent !important;
}

.j-youtube:before, .youtube:before {
  content: "" !important;
}

.btn{
  padding: 10px 12px;   
}

.form-control{
  height: initial;
  padding: 10px 12px;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: 0;
}

#intercom-container .intercom-launcher-button {
  background-color: rgba(0,0,0,0.55) !important;
  border: 2px solid #888888 !important;
}

input[type="text"], textarea, input[type="text"]:focus, textarea:focus {
  outline: none;
  box-shadow:none !important;
}

body .ember-application > div.ember-view {
  height: 100%;
}

#hoolio-www {
  position:relative; /* needed for footer positioning*/
  height:auto !important; /* real browsers */
  height:100%; /* IE6: treaded as min-height*/
  @include calc(min-height, "100% - 40px");
}

.hoolio-app {
  //padding-top: 100px;
  height:auto !important; /* real browsers */
  height:100%; /* IE6: treaded as min-height*/
  min-height:100%;

  &.no-navbar {
    padding-top: 0px;
  }

  &.no-footer {
    @include calc(min-height, "100%");
  }
}

#loading-hero {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 850px;
  margin: 0px;
  padding: 0px;
  background: url('img/general/hoolio1.jpg') no-repeat center center;
  background-size: cover;
  z-index: 999;
  text-align: center;
}

#generic-wormhole {
  z-index: 999;
}

.footer {
  position: absolute;
  bottom: -40px;
  width: 100%;
  margin: 0 0px 8px 0px;

  .copyright {
    font-size: 12px;
    color: $gray-lighter;
  }
}

[data-ember-action] {
  cursor: pointer;
}

ul, li {
  list-style-type: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

p {
  font-weight: 300;
}



input[type=range] {
    /*removes default webkit styles*/
    -webkit-appearance: none;
    cursor: pointer;
    
    /*fix for FF unable to apply focus style bug */
    border: none;
    background: transparent;
    /*required for proper track sizing in FF*/
    width: 100%;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    background: #ddd;
    border: none;
    border-radius: 10px;
}
input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #12beee;
    margin-top: -7px;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ddd;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 5px;
    background: #ddd;
    border: none;
    border-radius: 3px;
}
input[type=range]::-moz-range-thumb {
    border: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #12beee;
}

/*hide the outline behind the border*/
input[type=range]:-moz-focusring{
    outline: 1px solid #ddd;
    outline-offset: -1px;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 5px;
    
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #ddd;
    border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
}
input[type=range]::-ms-thumb {
    border: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #12beee;
}
input[type=range]:focus::-ms-fill-lower {
    background: #ddd;
}
input[type=range]:focus::-ms-fill-upper {
    background: #ddd;
}

/* The following only affects the range input in FF */

input[type="range"]::-moz-range-progress {
  background-color: #ddd; 
}

input[type="range"]::-moz-range-track {  
  background-color: #ddd;
}


div.row.no-padding > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

// Parsley
input.parsley-error, select.parsley-error, textarea.parsley-error {
  color: $input-color;
  background-color: #fff;
  border: 1px solid $state-danger-text;
}

input.parsley-success, select.parsley-success, textarea.parsley-success {
  color: $input-color;
  background-color: #fff;
  border: 1px solid $state-success-text;
}

.parsley-errors-list {
  margin: 2px 0 3px;
  font-size: 12px;
  color: $state-danger-text;
}
/* ===== */

.cursor-pointer{
  cursor: pointer;
}

.full-background {
  min-height: 100%;
  @include boost-animation();
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#212121', endColorstr='#212121', GradientType=1 );
  background-repeat: no-repeat !important;
  background-position: center center !important;
  /* background-attachment: fixed !important;  */
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
}

.tooltipster-default {
  border-radius: 5px; 
  border: 1px solid $gray-dark;
  background: $gray;
  color: #fff;
}
.tooltipster-light .tooltipster-content {
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  padding: 8px 10px;
}

.row.full-height-container {
  display: table;

  > [class*="col-"] {
    float: none;
    display: table-cell;
    vertical-align: top;
  }
}

.hoolio-play-btn {
  position: absolute;
  display:block;
  cursor: pointer;
  width: 80px; 
  height: 80px; 
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  background-color:$brand-primary;
  border: 4px solid $brand-primary;
  border-radius: 50%;

  &:before {
    content:'';
    display:block;
    position: absolute;
    width: 0; 
    height: 0; 
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: (20px*1.3) solid #fff;
    top: calc(50% - 20px);
    left: calc(50% - 8px);
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
  }

  &:after {
    content:'';
    opacity:0;
    transition: opacity (0.3s * 2);
    -webkit-transition: opacity (0.3s * 2);
    -moz-transition: opacity (0.3s * 2);
  }
  
  &:hover, &:focus {
    border-color: $brand-primary;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    background-color: #fff;

    .play-text {
      color: $brand-primary;
    }

    &:before {
      border-left-color: $brand-primary;
    }
  }

  &.is-loading:before {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    border: none;
    top: calc(50% - 20px);
    left: calc(50% - 20px);

    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
  }

  &.is-loading:hover:before, &.is-loading:focus:before {
    background-color: $brand-primary;
  }

  &.with-text {
    width: 185px;
    left: calc(50% - 92px);
    border-radius: 70px;

    &:before {
      left: 30px;
    }
  }
  
  &.active {
    border-color: transparent;
    &:after {
      content:'';
      opacity:1;
      width:(25px ) ;
      height:(25px * 1.6);
      background:#fff;
      position:absolute;
      right: (25px * 0.1);
      top: (25px * -0.8);
      border-left:(25px * 0.4) solid #fff;
      box-shadow:inset (25px * 0.6) 0 0 0 #fff;
    } 
  }
}

.btn-social-round {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  border: none;
  margin-right: 10px;
  font-size: 28px;
  color: #fff;

  &.btn-facebook-round {
   background-color: #46629E;
  }

  &.btn-twitter-round {
   background-color: #58CEF2;
  }

  &.btn-globe-round {
   background-color: #26C84F;
  }

  &.btn-pinterest-round {
   background-color: #BB1F23;
  }

  &.btn-instagram-round {
   background-color: #4AA4EF;
  }

  &:hover, &:focus {
    color: inherit;
  }
}


.expand-close-btn {
  position: absolute;
  cursor: pointer;
  top: 15px;
  right: 0px;
  width: 40px;
  height: 40px;
  padding: 4px;
  text-decoration: none;
  z-index: 999;

  @include boost-animation();

  &:after, &:before {
    position: absolute;
    display: block;
    content: '';
    width: 4px;
    height: 26px;
    left: 50%;
    background-color: #F5F5F5;//#de5c3d;
    border-radius: 2px;
}

  &:after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
 }

  &:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:hover:after, &:hover:after {
    -webkit-transition: -webkit-transform .3s ease-in-out;
    -moz-transition: -moz-transform .3s ease-in-out;
    -ms-transition: -ms-transform .3s ease-in-out;
    -o-transition: -o-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

@-webkit-keyframes colorBlink {
  from {color: rgba(18, 190, 238, 1)}//rgb(255, 59, 34)
  50% {color: rgba(255,255,255,1)}
  to {color: rgba(18, 190, 238, 1)}
}
@keyframes colorBlink {
  from {color: rgba(18, 190, 238, 1)}
  50% {color: rgba(255,255,255,1)}
  to {color: rgba(18, 190, 238, 1)}
}

@-webkit-keyframes blackBlink {
  from {color: rgba(0,0,0,1)}//rgb(255, 59, 34)
  50% {color: rgba(255,255,255,1)}
  to {color: rgba(0,0,0,1)}
}
@keyframes blackBlink {
  from {color: rgba(0,0,0,1)}
  50% {color: rgba(255,255,255,1)}
  to {color: rgba(0,0,0,1)}
}

@-webkit-keyframes redBlink {
  from {color: rgb(255, 59, 34)}
  50% {color: rgba(255,255,255,1)}
  to {color: rgb(255, 59, 34)}
}
@keyframes redBlink {
  from {color: rgb(255, 59, 34)}
  50% {color: rgba(255,255,255,1)}
  to {color: rgb(255, 59, 34)}
}

@keyframes rainbow {
  0% {background-color: #3BB3D1;}
  25% {background-color: #E1D892;}
  50% {background-color: #D4B2B8;}
  75% {background-color: #7CAABA;}
  100% {background-color: #ABA090;}
}

@-webkit-keyframes rainbow {
  0% {background-color: #3BB3D1;}
  25% {background-color: #E1D892;}
  50% {background-color: #D4B2B8;}
  75% {background-color: #7CAABA;}
  100% {background-color: #ABA090;}
}

@-webkit-keyframes circel_move {
  100% {
    -ms-transform: rotate(360deg); /* IE 9 */
    -webkit-transform: rotate(360deg); /* Chrome, Safari, Opera */
    transform: rotate(360deg); 
  }
}

@-moz-keyframes circel_move {
  100% {
    -ms-transform: rotate(360deg); /* IE 9 */
    -webkit-transform: rotate(360deg); /* Chrome, Safari, Opera */
    transform: rotate(360deg); 
  }
}



@keyframes circel_move {
  100% {
    -ms-transform: rotate(360deg); /* IE 9 */
    -webkit-transform: rotate(360deg); /* Chrome, Safari, Opera */
    transform: rotate(360deg); 
  }
}

@-webkit-keyframes blink {
  50% {
    opacity: 0.02;
  }
}

@-moz-keyframes blink {
    50% {
    opacity: 0.02;
  }
}


@keyframes blink {
  50% {
    opacity: 0.02;
  }
}


@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% { 
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
  } 50% { 
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
  } 100% { 
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}


/* Page media-queries */

/* Small devices (mobiles, 767px) */
@media (max-width: $screen-sm-min - 1) {
  .footer {
    .copyright {
      font-size: 8px;
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) { 
  
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) { 

}

