.pricing-modal {
  position: fixed;
  width: 100vw;
  z-index: 999;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(18, 18, 18, 0.95);
  overflow-x: hidden;
  overflow-y: auto;
  @include animate-duration(.2s);

  .btn.btn-transparent {
    padding: 8px 35px;
    border-color: #fff;
    @include transition(all .3s ease);

    &:hover, &:focus {
      border-color: $brand-primary;
      background-color: $brand-primary;
    }
  }

  .expand-close-btn {
    top: 85px;
  }

  .pricing-table {
    position: relative;
    margin: 0px auto;
    text-align: center;

    h2 {
      font-weight: 900;
    }
  }

  .plans {
    height: 630px;
    overflow: hidden;

    .col-sm-4 {

      &.selected {
        @include transition(all .3s ease);
        &#plan-0 .plan, &#plan-1 .plan, &#plan-2 .plan {
          border: 2px solid $gray;
        }
      }

      &.selected.isMobile {
        display: none;
      }

      &.not-selected {
        width: 0px;
        @include transition(all .3s ease);

        .plan {
          display: none;
        }
      }
    }

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      height: auto;

      .col-sm-4 {
        /* Small devices (mobiles, 767px) */
        @media (max-width: $screen-sm-min - 1) {
          &.selected {
            &#plan-0 .plan, &#plan-1 .plan, &#plan-2 .plan {
              border: none;
            }
          }
        }
      }
    }
  }

  .plan {
    border: 2px solid #fff;
    min-height: 100px;
    background: $body-bg;
    border-radius: 0px;
    margin: 25px 0;
    text-align: center;
    @include transition(all .3s ease);

    .head {
      padding: 12px 16px 0px 16px;
      min-height: 168px;

      h4 {
        text-transform: uppercase;
      }

      .price {
        margin: 30px 0;
        min-height: 52px;

        h6 {
          color: $brand-primary;
          span {
            text-decoration:line-through;
          }
        }

        h3 {
          margin: 0;
        }

        .per {
          font-size: 16px;
        }
      }
    }

    ul.item-list {
      background-color: #151515;
      list-style-type: none;
      padding: 20px;
      margin: 0;
      min-height: 128px;

      li {
        min-height: 22px;
      }
    }

    .action {
      min-height: 68px;
      margin: 40px 0;

      &.selected-label {
        background-color: $gray;
        padding-top: 17px;

        .selected-text {
          font-size: 20px;
          font-weight: 700;
        }
      }

      .btn-facebook-first {
        margin: 10px auto;
        max-width: 150px;
      }
    }

    &.free .action {
      margin: 20px 0;
    }

    .btn.btn-transparent {
      min-width: 150px;
      padding: 8px 35px;
    }

    .info {
      color: $brand-primary;
      font-size: 14;
    }

    .current-plan {
      margin-top: 40px;
      color: $brand-primary;
      line-height: 68px;
      font-size: 18px;
    }
  }

  #plan-0 .plan {
    border-right: none;

    .head .price {
      padding-top: 20px;
    }
  }

  #plan-1 .plan {
    margin-top: 0px;
    z-index: 99;
    border-color: $brand-primary;

    ul.item-list {
      padding: 20px 20px;
    }

    .btn {
      margin-bottom: 4px;
    }
  }

  #plan-2 .plan {
    border-left: none;
  }

  @media (min-height: 800px) {
    .pricing-table {
      margin: 60px auto;
    }
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    .pricing-table {
      position: relative;
      margin: 20px auto;
      text-align: center;

      h2 {
        font-weight: 900;
      }
    }

    .plan {
      border-left: none;
      border-right: none;
      margin: 0;
      height: 450px;

      .head {
        .price {
          margin: 10px 0;
        }
      }

      ul.item-list {
        padding: 10px;
      }

      .action {
        min-height: 68px;
        margin: 15px 0;
      }
    }

    #plan-1 .plan {
      border: none;
      height: 430px;

      ul.item-list {
        padding: 10px;
      }
    }

  }

  .checkout-info {
    max-width: 400px;
    padding-left: 40px !important;
    text-align: left;
    margin: 0 auto;
    @include animate-delay(.3s);

    &.isMobile {
      max-width: 100%;

      #card-form {
        max-width: 400px;
        margin: 0 auto;
      }
    }

    .btn {
      min-width: 124px;
    }

    #card-form {
      .form-group {
        margin-bottom: 10px;
      }

      .help-block {
        font-size: 14px;
        display: block;
        margin-top: 0px;
        margin-bottom: 10px;
        color: $brand-danger;
      }
    }

    .jp-card-focused {
      color: $brand-primary !important;
    }

    .btn.purchase-btn {
      border: 2px solid transparent;
    }

    .jp-card .jp-card-front, .jp-card .jp-card-back {
      background: $gray;
    }

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      padding: 20px !important;
    }
  }

  .register-info {
    max-width: 400px;
    padding-left: 40px !important;
    text-align: center;
    margin: 0 auto;
    @include animate-delay(.3s);

    &.isMobile {
      max-width: 100%;
    }

    .title {
      font-weight: 700;
      margin-top: 0px;
    }

    .info-text {
      text-align: center;
    }

    #register-modal-form, .social-login {
      margin: 0px auto;
      max-width: 400px;

      .form-group {
        margin-bottom: 10px;
      }
    }

    .btn.btn-success{
      min-width: 130px;
    }

    hr, .register hr {
      margin-top: 20px;
      margin-bottom: 20px;
      border-top: 1px $gray-light solid;
    }

    .signup-link {
      margin-top: 20px;
    }

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      padding: 20px !important;
    }

  }

  .complete-purchase {
    margin-top: 200px;

    h3 {
      font-weight: 700;
    }

    .premium-text {
      font-size: 22px;
      color: $brand-primary;
    }

    .trial-text {
      font-size: 22px;
    }

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      margin-top: 0px;
    }
  }
}