.studio-user-tooltip {
  
}

.studio-page .tooltipster-sidetip .tooltipster-box {
  border: none;
  background: rgba(61,61,61,0.83);
}

.studio-page .tooltipster-sidetip.tooltipster-right {
  max-width: 400px;
}

.studio-page .tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border,
.studio-page .tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border: none;
}

.studio-page .tooltipster-sidetip.tooltipster-right .tooltipster-arrow-uncropped {
  left: -15px;
}

.studio-page .tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background,
.studio-page .tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
    border-right-color: rgba(61,61,61,0.83);
}