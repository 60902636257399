$workout-feedback-bar-height: 140px;

.workout-feedback-component {
  position: relative;
  width: 100%;
  height: $workout-feedback-bar-height;
  background-color: rgba(55, 55, 55, 0.7);
  overflow: hidden;
  display: table;

  div.feedback-text {
    float: left;
    width: 20%;
    height: $workout-feedback-bar-height;
    font-size: 24px;
    font-weight: 700;
    display: table;
    text-align: center;
    padding: 10px;

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      font-size: 16px;
    }

    span {
      display: table-cell;
      vertical-align: middle;
    }
  }

  ul.faces-list {
    float: right;
    width: 80%;
    height: $workout-feedback-bar-height;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  li.face {
    position: relative;
    float: left;
    width: 20%;
    height: $workout-feedback-bar-height;
    cursor: pointer;
    text-align: center;
    @include transition(background-color .3s ease);
    
    img {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
      filter: gray; /* IE6-9 */
      -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
      @include transition(filter .3s ease);
    }

    &.selected {
      img {
        filter: none;
        -webkit-filter: grayscale(0%);
      }
    }

    &:hover {
      background-color: rgba(13, 13, 13, 0.5);
    }

    img {
      position: relative;
      margin: 0 auto;
      top: 50%;
      -ms-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}