.stars-tooltip-content {
  li {
    line-height: 12px;
    margin-bottom: 8px;
    font-size: 12px;
  }
  .progress {
    float: right;
    width: 100px;
    height: 12px;
    margin-bottom: 0;
    margin-left: 20px;
    background-color: $gray-light;

    .progress-bar {
      font-size: 10px;
      line-height: 12px;
      padding-left: 4px;
    }
  }
}