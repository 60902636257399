/************** @author: AKIS *****************/

/* ---------------------------------------------------------------------- */
/*  ui-modal bootstrap modal component
/* ---------------------------------------------------------------------- */
.modal.bs-modal {
  //@include animate-duration(.2s);

  div.expand-close-btn {
    top: 10px;
    right: 10px;
    opacity: 0.5;
  }

  .modal-dialog {
    //width: 600px;
    //margin: 30px auto;
    @include animate-duration(.4s);

    .modal-content {
      position:relative;
      overflow: hidden;
      border-radius: 10px;
      background-color: #222222;
      -webkit-box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.5);
      -moz-box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.5);
      box-shadow: 0px 10px 40px 0px rgba(0,0,0,0.5);
    }
  }

  .modal-header {
    text-align: center;
    color: #fff;
    background-color: #222222;
    border-bottom: none;
    padding: 15px;
    padding-top: 40px;
    h4 {
      font-weight: 700;
      margin: 0;
    }
  }

  .modal-body {
    min-height: 100px;
    padding: 20px 15px;
  }

  .modal-footer {
    /* border-top: 1px solid #e5e5e5; */
    text-align: right;
    background-color: #545454;
    padding: 10px;

    .btn-default {
      color: #fff;
      background-color: #545454;
      border-color: #545454;
    }
  }
}

.modal-backdrop.in {
  opacity: 0.6;
  filter: alpha(opacity=60);
}

@media (max-width: 768px) {
  .modal-dialog {
    
  }
}