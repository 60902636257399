.tile-fitnessplan-component.tile {
  
  .tile-body {
    position: relative;
    padding: 0;
  }

  .title-copy, .title-bg {
    top: auto;
    bottom: 0;
    transform: translate(0%, 0%);
    background-color: rgba(0,0,0,0.4);

    .title {
      font-weight: 700;
      font-size: 4vmin;
    }
  }

  .title-copy h2 {
    font-size: 3vmin;
  }

  .fit-workouts-btn {
    text-decoration: none;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .fitnessplan-completed {
    position:absolute;
    width: 100%;
    bottom: 30%;

    h3 {
      font-weight: 700;
      font-size: 22px;
    }
  }

  .active-fitnessplan {
    position: absolute;
    width: 100%;
    height: calc(#{$tile_height} * 2 );
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .video-thumb {
      height: calc(#{$tile_height} * 2 );
      &.youtube-thumb {
        width: calc((#{$tile_width} * 6) );
      }
    }

    .image-loader.responsive {
      width: auto;
      height: calc(#{$tile_height} * 2 ) !important;
    }

    .hoolio-play-btn {
      width: 66px;
      height: 66px;
      top: calc(50% - 40px);
    }

    .video-thumb .overlay {
      background-color: rgba(0,0,0,0.3);
    }

    .video-thumb .overlay .info-text {
      top: auto;
      padding: 7px 8px;
      width: 105%;
      right: -1px;
      bottom: 4px;
      border-radius: 0px;
    }
  }

  &.is-portrait {
    .active-fitnessplan { 
      .video-thumb {
        &.youtube-thumb {
          width: calc((#{$tile_width_portrait} * 6) - #{$tile_wall_gutter_portrait} );
        }
      }
    }
  }

  .workouts {
    margin-top: 10px;

    > span {
      float: left;
      margin-right: 5px;
      width: 10px;
      height: 16px;
      text-align: center;
    }

    .progress-bar-component {
      position:relative;
      top: 45%;
      width: 10px;
      .progress {
        margin-bottom: 0px;
      }
    }
  }

  .left-side {
    width: calc(100% - 80px);
    float: left;
  }

  .right-side {
    float: right;
  }

  .search-btn {
    width: 80px;
    height: 80px;
    margin-top: 5px;
    font-size: 32px;

    &.visible-xs-block {
      position: absolute;
      top: 4px;
      right: 4px;
      z-index: 99;
      width: 50px;
      height: 50px;
      margin-top: 0px;
      font-size: 22px;
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 

    .fitnessplan-completed {
      bottom: 50%;

      h3 {
        font-size: 32px;
      }
    }

    .active-fitnessplan {

      .hoolio-play-btn {
        width: 80px;
        height: 80px;
        top: calc(50% - 100px);
      }

      .video-thumb {
        height: calc(#{$tile_height} * 2 );
        min-height: 420px;
        &.youtube-thumb {
          width: calc((#{$tile_width} * 4) - #{$tile_wall_gutter});
          -ms-transform: scale(1.3, 1.3);
          -webkit-transform: scale(1.3, 1.3);
          transform: scale(1.3, 1.3);
        }
      }

      .image-loader.responsive {
        min-height: 420px;
      }

      .video-thumb .overlay .info-text {
        top: 50%;
        padding: 14px 16px;
        width: auto;
        right: auto;
        bottom: auto;
        border-radius: 6px;
      }
    }

    /* &.is-portrait {
      .active-fitnessplan { 
        .video-thumb {
          &.youtube-thumb {
            width: calc((#{$tile_width_portrait} * 4) );
          }
        }
      }
    } */
  }
}