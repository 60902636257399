.tile-treemap-component {
  cursor: pointer;

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 
    &.tile .tile-body {
      width: calc(100% - 30px);
      margin-left: 30px;
    }
  }
}