.library-page {
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;

  .locked {
    display: none;
    margin-left: -15px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(13, 13, 13, 0.9);
    @include transition(opacity .6s ease);

    .hide-btn {
      position: relative;
      color: #fff;
      top: 40px;
      font-size: 30px;
      font-weight: 700;

      &:hover, &:focus {
        background-color: $brand-primary;
      }
    }
  }

  

  #fu {
    > .fit-slider-item {
      width: 345px;
      height: 198px;
    }
  }

  .card-block {
    padding: 16px;
  }

  .fit-slider {
    margin: 3vw 0;
  }

  .fit-slider-container .fit-slider:first-child {
    margin: 1.5vw 0 3vw 0;
  }

  .circle-spinner {
    position: relative;
    height: 60px;
    width: 60px;
    animation: rotate 0.8s infinite linear;
    border: 2px solid $brand-primary;
    border-right-color: transparent;
    border-radius: 50%;
    margin: 60px auto;
  }


  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    padding-top: 100px;
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    
  }

  @keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
  }
}
