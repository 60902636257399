.studio-guide-component {
  position: fixed;
  width: 100%;
  top: 0;
  bottom:0;
  padding: 10px 0 100px 0;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: rgba(0,0,0,0.95);

  @include animation-duration(.3s);

  .channel-name {
    margin-left: 100px;
  }

  .week-list {
    position: relative;
    width: calc(100% - 100px);
    margin: 20px 0 20px 100px;
    background-color: rgba(255,255,255,0.1);

    li.weekday {
      float: left;
      height: 50px;
      width: 14.27%;
      text-align: center;
      padding: 3px 0;
      cursor: pointer;

      @include transition(background-color .3s ease);

      &:hover, &:focus {
        background-color: rgba(87,68,189, 1) !important;
      }
      
      &.selected-day {
        background-color: rgba(87,68,189, .6);
      }

      p {
        margin: 0;
      }
    }
  }

  .expand-close-btn {
    position: fixed;
    right: 15px;
  }

  .zone-title {
    display: block;
    margin-top: 20px;
    margin-left: 100px;
    //border-bottom: 1px solid #fff;
    padding: 6px 0;
    text-align: center;
    background-color: rgba(255,255,255,0.1);//rgba(18,190,238,0.4);
    color: #fff;
  }

  .panel-group {
    max-width: 800px;
    margin: 0 auto;
    max-height: 100%;
    overflow-y: auto;
  }

  .panel {
    border: none;
    border-radius: 0;
    background-color: transparent;
  }

  .panel-default {
    border-radius: 0;

    > .panel-heading {
      color: #fff;
      background-color: transparent;// background-color: rgba(255,255,255,0.4);
      border: none;
      border-radius: 0;
      @include transition(background-color .3s ease);

      &:hover, &:focus {
        background-color: rgba(87,68,189, 1) !important;
      }

      h4 {
        margin: 0;
      }

      .subzone-time {
        width: 85px;
      }

      
    }

    &.now-playing{

      > .panel-heading.collapsed {
        background-color: rgba(87,68,189, .6) !important;

        &:hover, &:focus {
          background-color: rgba(87,68,189, 1) !important;
          .subzone-time h4, .subzone-name h4{
            //font-weight: 700;
            animation: blackBlink 2s;
            animation-iteration-count: infinite;
            -webkit-animation: blackBlink 2s;
            -webkit-animation-iteration-count: infinite;
          }
        }

        .subzone-time h4, .subzone-name h4{
          //font-weight: 700;
          animation: colorBlink 2s;
          animation-iteration-count: infinite;
          -webkit-animation: colorBlink 2s;
          -webkit-animation-iteration-count: infinite;
        }
      }
    }

    &.expanded > .panel-heading.collapsed
      /* style */ {
      background-color: transparent;
    }

    &.expanded > .panel-heading
      /* style */ {
      background-color: rgba(87,68,189, .6);
    }
  }

  .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top: 2px solid rgba(0,0,0,0.9);
    background-color: transparent;
    padding: 0 0 0 90px;

    .media {
      padding: 10px;
      margin: 20px 0;

      &.now-playing {
        background-color: rgba(87,68,189, .6);

        span.c-red {
          margin-left: 4px;
          font-weight: 700;
          animation: colorBlink 2s;
          animation-iteration-count: infinite;
          -webkit-animation: colorBlink 2s;
          -webkit-animation-iteration-count: infinite;
        }
      }
    }

    .video-thumb {
      width: 240px;
    }
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    padding: 0px 0;

    .channel-name {
      margin-left: 15px;
      margin-right: 30px;
      
      h2 {
        font-size: 28px;
      }
    }

    .week-list {
      width: 100%;
      margin: 20px 0 20px 0px;
    }

    .zone-title {
      margin-top: 0px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .subzone-time h4{
      font-size: 16px;
    }

    .panel-default > .panel-heading + .panel-collapse > .panel-body {
      padding: 0 0 0 0px;
    }

    .media-left {
      display: block;

      .video-thumb {
        margin: 0 auto;
      }
    }

    .media-body {
      padding: 10px;
    }
  }
}


