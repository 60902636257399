/************** @author: Akis Volanis *****************/

/* Pages */
@import "./application/style";
@import "./library/style";
@import "./fitnessplans/style";
@import "./login-modal/style";
@import "./register-modal/style";
@import "./workout/style";
@import "./onboarding/style";
@import "./trainer/style";
@import "./user/style";
@import "./user-fitnessplan/style";
@import "./general-warning-modal/style";
@import "./workout/completed/style";
@import "./workout-invitation-modal/style";
@import "./activate/style";
@import "./pricing-modal/style";
@import "./search/style";
@import "./explore/style";
@import "./tv/style";
@import "./studio/style";
@import "./studios/style";
@import "./dashboard/style";
@import "./social-wall/style";

/* Components */
@import "./components/fit-slider/style";
@import "./components/fit-slider-item/style";
@import "./components/fit-slider-item-primary/style";
@import "./components/image-loader/style";
@import "./components/video-thumb/style";
@import "./components/kaltura-player/style";
@import "./components/bs-tab-list/style";
@import "./components/bs-tab-panel/style";
@import "./components/video-card/style";
@import "./components/video-row-info/style";
@import "./components/youtube-player/style";
@import "./components/fit-slider-container/style";
@import "./components/fitnessplan-main-secondary/style";
@import "./components/fitnessplan-card/style";
@import "./components/fitnessplan-row-info/style";
@import "./components/fitnessplan-weeks-slider/style";
@import "./components/bs-modal/style";
@import "./components/chat-box/style";
@import "./components/flickity-slider/style";
@import "./components/star-rating/style";
@import "./components/moves-bar/style";
@import "./components/week-range/style";
@import "./components/user-main-secondary/style";
@import "./components/fitnessplan-main-primary/style";
@import "./components/social-main-primary/style";
@import "./components/progress-bar/style";
@import "./components/user-main-primary/style";
@import "./components/background-image-effect/style";
@import "./components/image-uploader/style";
@import "./components/player-container/style";
@import "./components/player-controls/style";
@import "./components/webrtc-container/style";
@import "./components/stop-watch/style";
@import "./components/workout-qrcode/style";
@import "./components/workout-feedback/style";
@import "./components/spinning-button/style";
@import "./components/workout-invited-message/style";
@import "./components/compatibility-notification/style";
@import "./components/video-preview/style";
@import "./components/stars-tooltip/style";
@import "./components/social-main-secondary/style";
@import "./components/juicer-social-feed/style";
@import "./components/read-more/style";
@import "./components/video-annotations/style";
@import "./components/video-chapters/style";
@import "./components/hero-trainer/style";
@import "./components/hero-fitnessplan/style";
@import "./components/tree-map/style";
@import "./components/stack-blur/style";
@import "./components/explore-main-primary/style";
@import "./components/explore-main-secondary/style";
@import "./components/hero-row-item/style";
@import "./components/hero-competition/style";
@import "./components/rotating-card/style";
@import "./components/tv-guide/style";
@import "./components/studio-main-secondary/style";
@import "./components/tv-preview/style";
@import "./components/bs-dropdown/style";
@import "./components/studio-users-list/style";
@import "./components/studio-user-tooltip/style";
@import "./components/studio-guide/style";
@import "./components/studio-upnext/style";
@import "./components/add-to-calendar-button/style";
@import "./components/studio-row-info/style";
@import "./components/tile-wall/style";
@import "./components/tile-fitnessplan/style";
@import "./components/tile-social-corner/style";
@import "./components/tile-library/style";
@import "./components/tile-treemap/style";
@import "./components/tile-studios/style";
@import "./components/tile-studio/style";
@import "./components/tile-user/style";
@import "./components/tile-trainers/style";
@import "./components/tile-user-row/style";
@import "app/components/studio-card/style";
