.tile-trainers-component {

  &.tile .tile-body {
    padding: 0;

    /* .title-copy {
      padding: 2% 0;

      .title {
        background: rgba(0,0,0,.5);
      }
    }

    &:hover, &:focus {
      .title-copy {
        padding: 2% 0;

        .title {
          background: transparent;
        }
      }
    } */
  }

  .flickity-slider-component {
    width: 99.9%;
    height: 100%;
  }

  .flickity-slider-component .flickity-prev-next-button {
    background: rgba(255,255,255,0);
  }

  .hero-tile {
    cursor: pointer;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
  }
}