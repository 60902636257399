.fit-slider-container {
  overflow: hidden;
  @include transition(padding-bottom .5s cubic-bezier(0.5,0,.1,1) 0s);

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    & {
      &.is-expanded {
        padding-bottom: calc(#{$mobile-expanded-height}); // + 50px
      }
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 
    & {
      &.is-expanded {
        padding-bottom: calc(#{$tablet-expanded-height}); // + 50px
      }
    }
  }
}