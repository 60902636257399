.user-main-primary-component {
  position: relative;
  height: 100%;

  & > div:first-child {
    background: rgba(0,0,0,0.4);
    min-height: 100%;
  }

  .btn-transparent {
    position: relative;
    top: 10px;
  }

  .main-menu-slide {
    background-color: transparent;
    height: 100%;
  }

  .avatar-img {
    width: 80px;
    height: 80px !important;
    margin: 20px auto 0 auto;

    .img-content {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      border: 4px solid #fff;
    }

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      width: 80px;
      height: 80px !important;
      margin: 0px auto 0 auto;

      .img-content {
        width: 80px;
        height: 80px;
      }
    }

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) {
      width: 120px;
      height: 120px !important;
      margin: 7px auto 0 auto;
    }
  }

  .premium-badge {
    position: absolute;
    top: 60px;
    left: 100px;
    margin: 0 auto;

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) {
      top: 100px;
      left: 166px;
    }

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: $screen-md-min) {
      top: 100px;
      left: 200px;
    }

    .diamond {
      position: absolute;
      top: 10px;
      left: 8px;
    }

    .circle {
      position: relative;
      width: 30px; 
      height: 30px;
      border-radius: 50%;
      background: rgba(0,0,0,1);
    }
    .arc {
      overflow: hidden;
      position: absolute;
      top: -4px; 
      right: 50%; 
      bottom: 50%; 
      left: -4px;
      transform-origin: 100% 100%;
    }
    .arc:before {
      box-sizing: border-box;
      display: block;
      border: solid 4px #fff;
      width: 200%; 
      height: 200%;
      border-radius: 50%;
      transform: skewX(-30deg);
      content: '';
    }

    .arc1 {
      transform: rotate(120deg) skewX(30deg);
    }

    .arc2 {
      transform: rotate(180deg) skewX(30deg);
    }

    .arc3 {
      transform: rotate(225deg) skewX(30deg);
    }

    .arc4 {
      transform: rotate(260deg) skewX(30deg);
    }
  }

  hr {
    position: relative;
    opacity: .5;
    border-width: 2px;

    &.first {
      width: 80px;
    }

    &.second {
      width: 100px;
    }
  }

  .left-data {
    position: absolute;
    left: 10px;
    bottom: 0;
    width: calc( 50% - 10px);

    hr {
      &.first {
        left: -40px;
      }

      &.second {
        left: -30px;
      }
    }
  }

  .right-data {
    position: absolute;
    right: 10px;
    bottom: 0;
    width: calc( 50% - 10px);

    hr {
      &.first {
        left: 40px;
      }

      &.second {
        left: 30px;
        margin-bottom: 6px;
      }
    }
  }

  .stat {
    font-weight: 300;

    .progress-bar-component {
      .progress {
        background-color: rgba(255,255,255,0.4);
      }
    }

    .stat-title {
      font-size: 10px;
      margin: 4px 0;
    }
  }

  .stat.workouts {
    .progress-bar-component {
      float: left;
      width: 20px;
      margin-right: 5px;
    }
  }

  .stat.moves {
    .stat-title {
      margin-top: 0px;
      font-size: 14px;

      .moves-number {
        font-weight: 700;
      }
    }

    .progress-bar-component {
      float: right;
      width: 80%;
    }
  }
}

.main-menu-slide {
  position: relative;
  height: 100%;
  padding: 10px;
  background-color: $gray-dark;
}