.tv-page {
  overflow: hidden;

  .tv-navbar {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 46px;
    background-color: #cc181e;
    z-index: 999;
  }

  .hoolio-brand {
    position: absolute;
    top: 6px;
    left: 10px;
    z-index: 999;
    text-decoration: none;

    a {
      color: #fff;
      text-decoration: none;

      &:hover, &:focus {
        color: #fff;
      }
    }
  }

  .fullscreen-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 0px;
  }

  .now-playing-info {
    position: relative;
    font-size: 26px;
    font-weight: 700;
    line-height: 46px;
    margin: 0 auto;
    text-align: center;
  }

  .tv-guide-component {
    position: absolute;
    top: 46px;
    left: 0px;
    right: 0px;
    z-index: 998;
  }

  .no-feed {
    text-align: center;
    font-weight: 900;
    color: $gray;
    margin-top: 150px;
  }

  .youtube-player {
    z-index: 0;
  }
  
  #EmberYoutube-player {
    z-index: 999999;
    // pointer-events: none;
  }

  &.media-mobile {
    .now-playing-info {
      margin: 0 10px 0 0;
      float: right;
      font-size: 20px;
    }
  }
}