.stack-blur-component {
  position:absolute;
  overflow: hidden;
  left:0;
  right: 0;
  top:0;
  bottom: 0;
  z-index: -1;

  canvas{
    display: block;
    margin: 0 auto;
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    /* width: 100% !important;
    height: 100% !important; */
    /* left:0;
    right: 0;
    top:0;
    bottom: 0; */
    //z-index:0;
  }
}