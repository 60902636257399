.video-preview-component {

  .kaltura-player, .mwEmbedKalturaIframe {
    height: 300px !important;
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 
    .kaltura-player, .mwEmbedKalturaIframe {
      height: 480px !important;
    }
  }
}