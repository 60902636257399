$workout-navbar-height: 60px;
$workout-footer-height: 100px;
//$workout-left-sidebar-width: 150px;
//$workout-right-sidebar-width: 150px;

$workout-one-user-videos-width: 40%;
$workout-two-user-videos-width: 35%;
$workout-three-user-videos-width: 30%;

.workout-page {
  position: relative;
  overflow: hidden;
  height: 100vh;
  background: rgba(0,0,0,0.6);

  .animated {
    @include animation-duration(0.4s);

    &.slideInRight {
      @include animation-duration(1s);
    }
  }
  

  &.mouse-moved {
    .workout-navbar {
      background-color: rgba(0,0,0,0.4);
      z-index: 1000;
    }

    .workout-footer {
      background-color: rgba(0,0,0,0.4);
      z-index: 1000;
    }

    &.horizontal-layout {
      .two-users, .three-users {
        .OT_publisher .OT_edge-bar-item.OT_mode-on {
          top: $workout-navbar-height;
        }
      }
    }

    &.vertical-layout {

    }
  }

  &.player-paused {
    .workout-navbar {
      background-color: rgba(0,0,0,0.4);
    }

    .workout-footer {
      background-color: rgba(0,0,0,0.4);
    }
    
    &.horizontal-layout {
      .two-users, .three-users {
        .OT_publisher .OT_edge-bar-item.OT_mode-on {
          top: $workout-navbar-height;
        }
      }
    }

    &.vertical-layout {

    }
  }

  .workout-navbar {
    position: absolute;
    left: 0;
    width: 100%;
    height: $workout-navbar-height;
    z-index: 10;
    background-color: rgba(0,0,0,0);
    @include transition(background-color .4s ease);

    &:hover, &:focus {
      background-color: rgba(0,0,0,0.4);
    }

    &.youtube {
      
    }

    &.kaltura {
      
    }

    .header-btn {
      border-radius: 0;
      height: $workout-navbar-height;
      font-size: calc(#{$workout-navbar-height} / 3.3);
      
      .btn-text {
        line-height: 30px;
        margin-left: 10px;
      }
    }

    .back-btn {
      position:relative;
      float: left;
      width: $workout-navbar-height;
      font-size: calc(#{$workout-navbar-height} / 1.5);
      padding: 0 12px;
    }

    .finish-btn {
      position:relative;
      float: right;
      font-size: calc(#{$workout-navbar-height} / 2.5);
      color: #fff;
      background-color: $brand-primary;
      padding: 0 30px;
    }

    .hoolio-brand {
      display: block;
      height: $workout-navbar-height;
      float: left;
      img {
        width: 121px;
        height: 33px;
        margin: calc((#{$workout-navbar-height} - 33px)/2) 10px;
      }
    }

    .centered-actions {
      display: inline-block;

      .dropdown {
        display: inline-block;

        .dropdown-menu {
          color: #fff;
          border-radius: 0;
          background-color: rgba(0,0,0,0.4);
          padding: 0 0 10px 0;
          text-align: center;
          font-size: 14px;
          margin: 7px 5px 0 0px;
          left: -100px;
          @include animation-duration(.3s);

          > li button.btn {
            min-width: 130px;
          }

          /* Small devices (tablets, 768px and up) */
          @media (min-width: $screen-sm-min) { 
            left: 0px;
          }
        }

        .dropdown-copy {
          background-color: rgba(255,255,255,0.1);
          padding: 10px;

          h4 {
            font-weight: 700;
          }
        }

        .dropdown-image {
          img {
            display: block;
            margin: 5px auto 10px auto;

            &.social-img {
              width: 84px;
              height: 62px;
            }

            &.posture-img {
              width: 126px;
              height: 80px;
            }
          }
          padding: 10px;
        }

        button {
          //min-width: 130px;
        }
      }

      .header-btn.dropdown-toggle {
        background-color: rgba(255,255,255,0.1);
        width: 60px;

        /* Small devices (tablets, 768px and up) */
        @media (min-width: $screen-sm-min) { 
          width: 200px;
        }
      }
    }

    .layout-btn {
      img.horizontal-img {
        width: 28px;
        height: 17px;
      }

      img.vertical-img {
        width: 22px;
        height: 23px;
      }
    }

    .fullscreen-btn {
      position:relative;
      float: right;
      margin: 0px;
      @include transition(color .3s ease);

      &:hover {
        color: $brand-primary;
      }

      img {
        width: 32px;
        height: 26px;
      }
    }
  }

  .hoolio-brand-fullscreen {
    display: block;
    height: $workout-navbar-height;
    position: absolute;
    z-index: 999;

    &.horizontal-layout {
      top: 0px;
      left: 0px;
      right: initial;
      bottom: initial;

      &.two-users, &.three-users {
        top: initial;
        left: initial;
        right: 0px;
        bottom: 0px;
      }
    }

    &.vertical-layout {
      top: 0px;
      left: 0px;
      right: initial;
      bottom: initial;
    }

    img {
      width: 121px;
      height: 33px;
      margin: calc((#{$workout-navbar-height} - 33px)/2) 10px;
    }
  }

  .fullscreen-small-btn {
    display: block;
    position: absolute;
    width: $workout-navbar-height;
    height: $workout-navbar-height;
    top: 0;
    right: 0;
    z-index: 999;

    img {
      width: 32px;
      height: 26px;
    }
  }

  .workout-main {
    position: absolute;
    width: 100%;

    &.youtube {
      top: $workout-navbar-height;
      height: calc(100% - #{$workout-navbar-height} - #{$workout-footer-height});
    }

    &.kaltura {
      top: 0px;
      height: 100%;
    }

    .webrtc-container {
      &.horizontal-layout {
        float: left;
        width: 0;
      }

      &.vertical-layout {
        float: none;
        width: 100%;
      }
    }

    .player-container {
      &.horizontal-layout {
        float: right;
        width: 100%;
      }

      &.vertical-layout {
        float: none;
        width: 100%;
      }
    }

    &.horizontal-layout {
      &.one-user {
        .webrtc-container {
          width: $workout-one-user-videos-width;
        }

        .player-container {
          width: calc(100% - #{$workout-one-user-videos-width});
        }
      }

      &.two-users {
        .webrtc-container {
          width: $workout-two-user-videos-width;
        }

        .player-container {
          width: calc(100% - #{$workout-two-user-videos-width});
        }
      }

      &.three-users {
        .webrtc-container {
          width: $workout-three-user-videos-width;
        }

        .player-container {
          width: calc(100% - #{$workout-three-user-videos-width});
        }
      }
    }

    &.vertical-layout {
      &.one-user {
        .webrtc-container {
          width: 100%;
          height: $workout-one-user-videos-width;
        }

        .player-container {
          width: 100%;
          height: calc(100% - #{$workout-one-user-videos-width});
        }
      }

      &.two-users {
        .webrtc-container {
          width: 100%;
          height: $workout-two-user-videos-width;
        }

        .player-container {
          width: 100%;
          height: calc(100% - #{$workout-two-user-videos-width});
        }
      }

      &.three-users {
        .webrtc-container {
          width: 100%;
          height: $workout-three-user-videos-width;
        }

        .player-container {
          width: 100%;
          height: calc(100% - #{$workout-three-user-videos-width});
        }
      }
    }

    /* .left-sidebar, .right-sidebar {
      position: absolute;
      top: 0;
      background-color: rgba(0,0,0,0.4);
    }

    .left-sidebar {
      left: 0px;
      width: $workout-left-sidebar-width;
      height: 100%;
    }

    .right-sidebar {
      right: 0px;
      width: $workout-right-sidebar-width;
      height: 100%;
    } */
  }

  .workout-footer {
    position: absolute;
    height: $workout-footer-height;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0,0,0,0);
    //overflow: hidden;
    @include transition(background-color .4s ease);

    &:hover, &:focus {
      background-color: rgba(0,0,0,0.4);
    }

    &.youtube {
      
    }

    &.kaltura {
      
    }

    .heart-rate, .steps {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      max-width: 100px;
      height: $workout-footer-height;
      margin: 0 10px;
      overflow: hidden;

      .hear-rate-value, .steps-value {
        line-height: 74px;
        font-size: calc(#{$workout-footer-height} / 4);
        font-weight: 700;
        display: block;
        float: right;
        width: 100%;
      }

      .hear-rate-value {
        line-height: initial;
        
        span.rate-value {
          line-height: 74px;
        }

        span.rate-heart {
          font-size: 16px;
          float: right;
          padding-left: 10px;
          padding-top: 14px;
          i {
            font-size: 20px;
            color: red;
            opacity: .5;

            &.beating {
              opacity: 1;
              animation: heartbeat 2s infinite;
              -webkit-animation: heartbeat 2s infinite;
            }
          }
        }
      }
    }

    .workout-time {
      display: inline-block;
      vertical-align: top;
      height: $workout-footer-height;
      //padding: 0px 12px;
      margin: 0 10px;
      overflow: hidden;

      .workout-time-value {
        line-height: 70px;
        font-size: calc(#{$workout-footer-height} / 4);
        font-weight: 700;
        display: block;

        small {
          font-size: calc(#{$workout-footer-height} / 6);
        }
      }
    }

    .control-btn, .chapters-dropup {
      display: inline-block;
      vertical-align: top;
      max-width: $workout-footer-height;
      height: $workout-footer-height;
      font-size: calc(#{$workout-footer-height} / 4);
      margin: 0 10px;
      background-color: rgba(0,0,0,0);
      @include transition(color .3s ease);

      &:not(.disabled):hover {
        color: $brand-primary;
      }

      &:active, &:focus {
        background-color: rgba(0,0,0,0) !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }

      /* &:active {
        color: rgba(18, 190, 238, .6);
      } */
    }

    .chapters-dropup {
      &.open .control-btn{
        color: $brand-primary;
      }

      .dropdown-menu {
        min-width: 450px;
        max-height: 400px;
        overflow-y: auto;
        background-color: rgba(0,0,0,0.7);
        color: #fff;
        padding-top: 0;
        padding-bottom: 6px;

        li {
          font-size: 20px;
          line-height: 30px;
          overflow: hidden;
          white-space: nowrap;

          > span:first-child {
            text-align: right;
            padding-right: 0;
          }

          > span:nth-child(2) {
            padding-right: 0;
          }

          > span:last-child {
            padding-right: 0;
          }
        }

        li.active{
          color: $brand-primary !important;
          animation: colorBlink 2s;
          animation-iteration-count: infinite;
          -webkit-animation: colorBlink 2s;
          -webkit-animation-iteration-count: infinite;

          span {
            background-color: rgba(18, 190, 238,0.4);
          }
        }

        li:hover {
          span {
            background-color: rgba(255,255,255,0.1);
          }
        }
      }

      .dropdown-header {
        font-size: 18px;
        color: #fff;
        font-weight: 600;
        background: rgba(0,0,0,0.2);
      }
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    .workout-footer {

      .heart-rate, .steps, .workout-time, .control-btn {
        margin: 0 15px;
      }

      .hear-rate-value, .steps-value, .workout-time .workout-time-value, .control-btn {
        font-size: calc(#{$workout-footer-height} / 3);
        max-height: 74px;
      }
      
      .chapters-dropup {
        .dropdown-menu {
          left: auto;
          right: auto;
          transform: translate(-50%, 0);
        }
      }
    }
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: $screen-lg-min) { 
    .workout-footer {

      .heart-rate, .steps, .workout-time, .control-btn {
        margin: 0 20px;
      }

      .hear-rate-value, .steps-value, .workout-time .workout-time-value, .control-btn {
        font-size: calc(#{$workout-footer-height} / 2);
        max-height: 74px;
      }
    }
  }
}