.fit-slider-item {
  position: relative;
  width: 180px;
  height: 103px;
  margin-right: 4px;

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    width: 280px;
    height: 160px;
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    width: 345px;
    height: 196px;
  }
}


