.hero-fitnessplan-component {
  position: relative;

  .trainer-bg {
    position: absolute;
    z-index: -1;
    min-width: 100%;
    min-height: 100%;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
  }

  .content {
    text-align: center;
    padding: 125px 15px 0 15px;
    color: #fff;

    h1 {
      font-weight: 700;
    }

    .sub-text {
      margin: 20px 0;
      font-size: 26px;
    }
  }

  .bottom-gradien {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    width: 100%;
    height: 200px;
    background-image: url(img/gradients/hero_gradient3.png);
    background-repeat: repeat-x; 
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 
    .content {
      text-align: left;
      padding: 170px;
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: $screen-lg-min) { 

  }
}