.hoolio-tv-main-secondary-component {
  position: relative;
  height: $tablet-expanded-height;
  background: url("https://cfvod.kaltura.com/p/1968091/sp/196809100/thumbnail/entry_id/1_rh1sez5x/width/1920") no-repeat center center;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;

  .copy {
    z-index: 1;
    left: 10%;
    position: relative;
    margin-top: 100px;
    margin-right: 15px;
    p {
      max-width: 600px;
    }

    .btn-transparent {
      background: #cc181e;
    }
  }

  .now-playing {
    padding: 100px 20px 0 40px;

    p {
      display: inline-block;
      background: #cc181e;
      padding: 5px 10px;
    }
    .image-loader {
      display: block;
      border: 4px solid #fff;
      width:100%;
      max-width: 400px;
    }
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    height: $mobile-expanded-height;
    text-align: center;

    .copy {
      left: auto;
      margin: 100px 15px 0 15px;
    }

    .now-playing {
      padding-top: 20px;

      .image-loader {
        margin: 0 auto;
        max-width: 380px;
      }
    }
  }
}