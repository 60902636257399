.image-loader {
  @include aspect-ratio(16,9);
  overflow: hidden;

  &.bordered {
    border: 3px #fff solid;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.5);
  }

  &.responsive {
    width: 100%;
    height: auto !important;
  }

  &.youtube-thumb{
    .img-content {

    }
  }

  /* &.scaled{
    .img-content {
      transform: scale(1.2, 1.2);
      -ms-transform: scale(1.2, 1.2);
      -webkit-transform: scale(1.2, 1.2);
    }
  }

  &.scale-effect {
    -webkit-transition: -webkit-transform 1s ease-in-out;
    -moz-transition: -moz-transform 1s ease-in-out;
    -ms-transition: -ms-transform 1s ease-in-out;
    -o-transition: -o-transform 1s ease-in-out;
    transition: transform 1s ease-in-out;

    &:hover {
      -ms-transform: scale(1.05, 1.05);
      -webkit-transform: scale(1.05, 1.05);
      transform: scale(1.05, 1.05);
    }
  } */

  .img-content {
    margin: 0 auto;
    background-position: 50% 50%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    //@include boost-animation();
    @include animate-duration(.6s);
  }

  img.hidden-img{
    display: none !important;
  }

  .circle-spinner {
    position: absolute;
    height: 28px;
    width: 28px;
    @include calc(top, "50% - 14px");
    @include calc(right, "50% - 16px");
    animation: rotate 0.8s infinite linear;
    border: 2px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
    margin: 0;
  }

  @keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
  }
}