.tile-studios-component {
  cursor: pointer;

  &.tile .tile-body {
    .title-copy .title-img {
      width: 40%;
      height: 40%;
      max-width: 154px;
      max-height: 40px;
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 
    &.tile .tile-body {
      width: calc(100% - 30px);
      margin-left: 30px;

      &:hover, &:focus {
        .overlay {
          background-color: rgba(87,68,189, .6);
        }
      }
    }
  }
}