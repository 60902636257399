.workout-invited-message {

}

#generic-wormhole .workout-invited-message-wormhole {
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
  @include animation-duration(.3s);

  .expand-close-btn {
    top: -40px;
    right: -60px;
  }

  .overlay {
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.9);
  }

  .workout-invited-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    h3 {
      font-weight: 700;
    }

    .camera-status-on {
      color: $brand-success;
    }

    .camera-status-off {
      color: #FF4112;
    }

    p {
      max-width: 340px;
    }
  }
}