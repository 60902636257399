.video-row-info {
  position: relative;
  padding: 0 15px;
  min-height: 100%;
  background-color: $gray-dark;

  /* .left-side, .right-side {
    padding-bottom: 1000px;
    margin-bottom: -1000px;
    height: 100%;
  } */

  .overview-data {
    padding-left: 15px !important;

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      height: calc(700px - 300px);
    }

    &.isMobile {
      /* Small devices (mobiles, 767px) */
      @media (max-width: $screen-sm-min - 1) {
        height: calc(700px - 510px);
      }
    }
  }

  .title {
    text-transform: uppercase;
    font-weight: 900;
    margin-right: 30px;
  }

  .actions {
    margin: 10px 0;
  }

  .trainer {
    margin: 10px 0;

    .name.is-featured {
      color: $brand-primary;
    }
  }

  .favorite-btn {
    position: relative;
    top: 2px;
    float: left;
    left: 15px;
    font-size: 20px;
    width: 20px;
    
    .fa {
      @include transition(color .3s ease);
    }

    .fa.fa-heart-o:hover {
      color: $brand-danger;
    }

    .fa.fa-heart {
      color: $brand-danger;
    }
  }

  .star-rating-container {
    position: relative;
    top: 4px;
    float: left;
    margin-right: 10px;
  }

  .video-badge {
    position: relative;
    float: right;
    bottom: 5px;
    right: 15px;
    padding: 6px 12px;
    border-radius: 70px;
    background-color: #2b2b2b;
  }

  .stats {
    margin: 20px 0;
    padding: 10px 0;
    border-top: 2px solid #383838;
    border-bottom: 2px solid #383838;

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      margin: 20px -15px;
    }
  }

  .stat {
    padding: 0 15px;
    text-align: left;

    .stat-text-top {
      font-size: 12px;
      font-weight: 300;
      color: $brand-warning;
    }
    .stat-text {
      font-size: 18px;
      font-weight: 700;
    }

    @media (min-width: 1100px) {
      .stat-text-top {
        font-size: 14px;
      }
      .stat-text {
        font-size: 24px;
      }
    }

    @media (min-width: 1500px) {
      .stat-text {
        font-size: 30px;
      }
    }

    @media (min-width: 1700px) {
      .stat-text {
        font-size: 34px;
      }
    }
  }

  .right-side {
    p {
      margin: 15px;
    }
  }

  .video-thumb {
    height: $tablet-expanded-height;//calc(#{$tablet-expanded-height} - 46px);

    /* Small devices (mobiles, 767px) */
    @media (max-width: $screen-sm-min - 1) {
      height: 300px;
    }

    .image-loader {
      position: inherit !important;
    }
  }

  .upgrade-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: rgba(0,0,0,0.6);
    text-align: center;
    padding: 10px 10px 0 10px;
    z-index: 10;

    .pricing-button {
      padding: 10px 15px;
    }

    .reply-button {
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.5);
      border-radius: 10px 0 0 0;
      font-size: 24px;
    }
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    .stats {
      margin-top: 10px;
    }

    .stat {
      padding: 0 15px;
      text-align: center;
      .stat-text {
        font-size: 18px;
      }
    }

    .img-content {
      transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    .img-content {
      transform: scale(1.05, 1.05);
      -ms-transform: scale(1.05, 1.05);
      -webkit-transform: scale(1.05, 1.05);
    }

    .upgrade-info {
      padding: 100px 40px 0 80px;

      .pricing-button {
        padding: 10px 30px;
      }
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    
  }

  /* Large devices (large desktops, 1400px and up) */
  @media (min-width: 1480px) {
    .img-content {
      transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
    }
  }
}