.social-main-secondary {
  height: 100%;
}

.social-main-secondary-component {
  position: relative;
  height: $tablet-expanded-height;

  .juicer-social-feed {
    width: calc(100% - 30px);
    height: $tablet-expanded-height;
    overflow-y: scroll;
    margin: 0 15px;
  }

  > .row {
    position: relative;
    width: 100%;
    height: $tablet-expanded-height;
  }

  .social-banner {
    position: relative;
    width: 100%;
    height: calc(#{$tablet-expanded-height} - 250px);
    margin: 0px 0 20px 0;
    padding: 0px;
    background: url('img/general/hero_2.jpg') no-repeat center left;
    background-size: cover;
    z-index: 1;
    text-align: center;
  }

  #instafeed {
    position: relative;
    z-index: 2;
    padding: 0 30px;
    background: transparent;

    .slider-cell {
      position: relative;
      margin-left: 10px;

      .caption {
        position:absolute;
        top:0;
        right:0;
        background:rgba(0,0,0,0.75);
        width:100%;
        height:100%;
        padding:2%;
        opacity: 0;
        text-align:center;
        color:#fff !important;
        z-index:2;
        @include transition(opacity .4s ease);

        &:hover, &:focus {
          opacity: 1;
        }
      }
    }
  }


  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    height: $mobile-expanded-height;

    .juicer-social-feed {
      width: 100%;
      height: $mobile-expanded-height;
    }

    > .row {
      position: relative;
      height: $mobile-expanded-height;
    }

    .social-banner {
      height: calc(#{$mobile-expanded-height} - 250px);
    }
  }
}