.studio-row-info {
  position: relative;
  height: $tablet-expanded-height;
  //background: url("https://cfvod.kaltura.com/p/1968091/sp/196809100/thumbnail/entry_id/1_rh1sez5x/width/1920") no-repeat center center;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;

  .users-online {
    position: absolute;
    top: 10px;
    left: 35px;
    width: 80px;
    height: 80px;
    background-color: rgba(87,68,189, .2);
    border: 1px solid rgba(87,68,189, 1);
    border-radius: 50%;
    text-align: center;
    padding: 14px 0 0 0;

    img {
      margin: 0 auto;
    }

    p {
      font-size: 12px;
      margin-bottom: 0px;
    }

    span {
      font-size: 20px;
      font-weight: 400;
    }
  }

  .copy {
    z-index: 1;
    left: 0%;
    position: relative;
    margin-top: 100px;
    margin-right: 15px;
    p {
      max-width: 600px;
    }

    .btn-transparent {
      background: rgba(87,68,189, 1);
    }
  }

  .now-playing {
    padding: 60px 20px 0 20px;
  }

  .now-playing-wrapper {
    text-align: left;
    padding: 20px 30px;
    width: calc(100% - 30px);
    max-width: 506px;
    background-color: rgba(87,68,189, .2);
    border: 1px solid rgba(87,68,189, 1);

    .video-tile {
      width: 100%;
      padding: 0 5px;
    }

    .header {
      margin: 0px;
      font-weight: 700;
    }

    .title {
      position: relative;
      display: inline-block;
      width: 100%;
      border-bottom: 1px dotted #fff;
      padding-right: 70px;

      span.time {
        position: absolute;
        right: 0px;
      }

      span.duration-time {
        font-size: 12px;
      }
    }

    .image-loader {
      display: block;
      border: 4px solid #fff;
      width:100%;
      max-width: 500px;
    }
  }

  .flickity-prev-next-button {
    opacity: 1;
  }

  .flickity-slider-component .flickity-prev-next-button {
    height: 40px;
    top: 20px;
    right: -20px;
    background: transparent;

    // &:hover {
    //   .arrow {
    //     fill: rgba(87,68,189, 1);
    //   }
    // }
  }

  .flickity-slider-component .flickity-prev-next-button.next {
    right: -25px;
  }

  .flickity-slider-component .flickity-prev-next-button.previous {
    left: -25px;
  }

  /* Small devices (mobiles, 767px) */
  @media (max-width: $screen-sm-min - 1) {
    height: $mobile-expanded-height;
    text-align: center;

    .users-online {
      top: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      height: 80px;
      border: none;
      border-bottom: 1px solid rgba(87,68,189, 1);
      border-radius: 0px;
      padding: 14px 0 0 0;
    }

    .copy {
      left: auto;
      margin: 90px 15px 0 15px;

      h1 {
        font-size: 38px;
      }
    }

    .now-playing {
      padding-top: 20px;
    }

    .now-playing-wrapper {
      margin: 0 auto;
    }
  }

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 
    
    .copy {
      
      p {
        font-size: 18px;
        max-width: 600px;
      }

      .btn-transparent {
        background: rgba(87,68,189, 1);
      }
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    
  }
}