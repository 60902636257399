.dashboard-page {
  position: relative;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  min-height: calc(100vh - 40px);
  background-repeat: no-repeat !important;
  background-position: center center !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;

  .tile-wall-wrapper {
    padding: 0;
  }

  .tile:nth-child(1) {
    @include animate-delay(.1s);
  }

  .tile:nth-child(2) {
    @include animate-delay(.2s);
  }

  .tile:nth-child(3) {
    @include animate-delay(.3s);
  }

  .tile:nth-child(4) {
    @include animate-delay(.4s);
  }

  .tile:nth-child(5) {
    @include animate-delay(.5s);
  }

  .tile:nth-child(6) {
    @include animate-delay(.6s);
  }

  .tile:nth-child(7) {
    @include animate-delay(.7);
  }

  .tile:nth-child(8) {
    @include animate-delay(.8s);
  }

  .locked {
    display: none;
    margin-left: -15px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba(13, 13, 13, 0.9);
    @include transition(opacity .6s ease);

    .hide-btn {
      position: relative;
      color: #fff;
      top: 40px;
      font-size: 30px;
      font-weight: 700;

      &:hover, &:focus {
        background-color: $brand-primary;
      }
    }
  }

  .top-jumbotron {
    position: relative;
    background: $body-bg;
    margin-bottom: calc(120px + 3vw);

    .hero-slider-container {
      position: relative;
      top: -100px;
      width: 100%;
      height: 700px;
      overflow: hidden;
    }

    .hero-row-container {
      position: absolute;
      top: 480px;
      padding-left: 15px !important;
      padding-right: 15px !important;

      .slider-title {
        color: #9C9C9C;
        font-size: 3vw;
        font-weight: 700;
        padding-left: calc((100% - 1530px) / 2);
      }
    }

    .hero-slider.flickity-slider-component {
      //background: $body-bg;

      /* Fade CSS */
      .flickity-slider {
        transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
      }

      .hero-component {
        left: 0 !important;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        -webkit-transition: opacity 0.3s ease-in-out;
        z-index: -1;
      }

      .hero-component.is-selected {
        opacity: 1;
        z-index: 0
      }

      .flickity-viewport {
        width: 100%;
        height: 700px;
      }

      .flickity-prev-next-button {
        top: 40%;
        height: 40px;
      }
    }

    /* cell inherit height from carousel */
    .hero-component {
      width: 100%;
      height: 100%;
    }
  }

  .library-treemap {
    //min-height: 700px;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;

    &.expand-height {
      min-height: 1215px;
    }

    .treemap-copy {
      padding: 0 15px;
    }

    .tree-map-component {
      max-width: 100vw !important;
    }

    .fit-slider .slider-title {
      color: #fff;
    }

    .video-row-info .overview-data {
      padding-left: 30px !important;
    }
  }

  #fu {
    > .fit-slider-item {
      width: 345px;
      height: 198px;
    }
  }

  .card-block {
    padding: 16px;
  }

  .fit-slider-container.hero-margin {
    margin-top: 460px;
  }

  .fit-slider {
    margin: 3vw 0;
  }

  .circle-spinner {
    position: relative;
    height: 60px;
    width: 60px;
    animation: rotate 0.8s infinite linear;
    border: 2px solid $brand-primary;
    border-right-color: transparent;
    border-radius: 50%;
    margin: 60px auto;
  }


  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) {
    padding-top: 100px;

    .top-jumbotron {
      margin-bottom: calc(220px + 2vw);

      .hero-row-container {
        .slider-title {
          font-size: 2vw;
        }
      }
    }

    .library-treemap {
    
      &.expand-height {
        min-height: 1212px;
      }
    }
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    .top-jumbotron {
      margin-bottom: calc(253px + 1.4vw);

      .hero-row-container {
        .slider-title {
          font-size: 1.4vw;
        }
      }
    }

    .library-treemap {
    
      &.expand-height {
        min-height: 1443px;
      }
    }
  }

  @keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
  }
}