.flickity-slider-component {
  background: transparent;
  

  .flickity-page-dots {
    top: -19px;
    right: 0px;
    width: auto;
    height: 20px;
    opacity: 0;
    @include transition(opacity .3s ease-in-out);

    &:hover {
      .dot {
        height: 10px;
      }
    }
  }
  /* dots are lines */
  .flickity-page-dots .dot {
    height: 2px;
    width: 10px;
    margin: 0;
    border-radius: 0;
    background: $gray;
    @include transition(background .3s ease);
    @include transition(height .3s ease);

    &.is-selected {
      background: $brand-primary;
    }
  }

  .flickity-prev-next-button {
    position: absolute;
    top: 50%;
    padding: 0;
    width: 24px;
    height: calc(100% + 2px);
    border: none;
    border-radius: 0;
    background: rgba(18,18,18,0.7);
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-animation: fadein .6s;
       -moz-animation: fadein .6s;
        -ms-animation: fadein .6s;
         -o-animation: fadein .6s;
            animation: fadein .6s;

    opacity: 0;
    @include transition(opacity .3s ease-in-out);

    svg {
      left: 0%;
      top: 0%;
      width: 100%;
      height: 100%;
    }

    &:active {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    &:disabled {
      display: none;
    }

    .arrow {
      fill: #fff;
    }

    &:hover {
      .arrow {
        fill: $brand-primary;
      }
    }
  }

  .flickity-prev-next-button.previous {
    left: 0px;
  }

  .flickity-prev-next-button.next {
    right: 0px;
  }

  &:hover, &:focus {
    .flickity-page-dots {
      opacity: 1;
    }

    .flickity-prev-next-button {
      opacity: 1;
    }
  }

  @media (max-width: $screen-sm-min - 1) {
    .flickity-page-dots {
      display: none;
    }
  }
}