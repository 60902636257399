/**
 * AddToCalendar red Style
 * http://addtocalendar.com
 */

/* Base */
.btn.addtocalendar {
  padding: 0;
  margin: 10px 0;
  min-width: 100%;
}

.addtocalendar var{
    display: none;
}

.addtocalendar {
    position: relative;
    display: inline-block;
    background: transparent!important;
}

.atcb-link {
    display: block;
    outline: none!important;
    cursor: pointer;
}

.atcb-link:focus~ul,
.atcb-link:active~ul,
.atcb-list:hover{
    visibility:visible;
}

.atcb-list {
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    width: 170px;
    z-index: 900;
}

.atcb-list,
.atcb-item
{
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;
}

.atcb-item {
    float: none;
    text-align: left;
}

.atcb-item-link
{
    text-decoration: none;
    outline: none;
    display: block;
}

.atcb-item.hover,
.atcb-item:hover {
    position: relative;
    z-index: 900;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}


/* red */

.atc-style-red .atcb-link,
.atc-style-red .atcb-link:hover,
.atc-style-red .atcb-link:active,
.atc-style-red .atcb-link:focus
{
    margin: 0;
    padding: 7px 25px;
    color: #262626;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    outline: none;
    line-height: 20px;
    vertical-align: middle;
    background: #fff;
    box-shadow: 0 0 40px rgba(0, 0, 0, .13) inset;
    border-radius: 4px;
    zoom: 1;
}

.atc-style-red .atcb-link:hover,
.atc-style-red .atcb-link:active,
.atc-style-red .atcb-link:focus
{
    color: $brand-danger;
  
}

.atc-style-red .atcb-list {
    width: 170px;
    border: 1px solid rgb(186,186,186);
    border-radius: 2px;
    box-shadow: 0 0 5px #AAA;
}

.atc-style-red .atcb-list,
.atc-style-red .atcb-item
{
    background: #fff;
    color: #000;
}

.atc-style-red .atcb-item,
.atc-style-red .atcb-item-link
{
    line-height: 1.3em;
    vertical-align: middle;
    zoom: 1;
}

.atc-style-red .atcb-item-link,
.atc-style-red .atcb-item-link:hover,
.atc-style-red .atcb-item-link:active,
.atc-style-red .atcb-item-link:focus
{
    color: #000;
    font-family: "Verdana";
    font-size: 14px;
    text-decoration: none;
    outline: none;
    padding: 5px 15px;
}

.atc-style-red .atcb-item-link:hover,
.atc-style-red .atcb-item-link:active,
.atc-style-red .atcb-item-link:focus
{
    color: $brand-danger;
}

.atc-style-red .atcb-item.hover,
.atc-style-red .atcb-item:hover {
    background: #fff;
}
