.workout-completed-page {
  position: relative;
  min-height: 100vh;
  background: rgba(0,0,0,0.6);

  /* .video-thumb {
    max-height: 600px;
  } */

  .workout-navbar {
    position: relative;
    left: 0;
    width: 100%;
    height: $workout-navbar-height;
    z-index: 10;
    background-color: rgba(0,0,0,0.4);

    .header-btn {
      border-radius: 0;
      height: $workout-navbar-height;
      font-size: calc(#{$workout-navbar-height} / 3.3);
      
      .btn-text {
        line-height: 30px;
        margin-left: 10px;
      }
    }

/*     .back-btn {
      position:relative;
      float: left;
      width: $workout-navbar-height;
      font-size: calc(#{$workout-navbar-height} / 1.5);
      padding: 0 12px;
    } */

    .hoolio-brand {
      display: block;
      height: $workout-navbar-height;
      float: left;
      img {
        width: 121px;
        height: 33px;
        margin: calc((#{$workout-navbar-height} - 33px)/2) 10px;
      }
    }

    .centered-actions {
      display: inline-block;
      background-color: rgba(55, 55, 55, 0.7);
      padding: 0 20px;
      .media {
        height: $workout-navbar-height;
        .media-left img{
          position: relative;
          top: 10px;
        }

        .media-body {
          width: auto;
        }

        .media-heading {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 24px;
          margin-top: 15px;
        }
      }
    }

    .finish-btn {
      position:relative;
      float: right;
      font-size: calc(#{$workout-navbar-height} / 2.5);
      color: #fff;
      background-color: $brand-primary;
      padding: 0 30px;
    }

    .fullscreen-btn {
      position:relative;
      float: right;
      @include transition(color .3s ease);

      &:hover {
        color: $brand-primary;
      }

      img {
        width: 32px;
        height: 26px;
      }
    }
  }

  .workout-main {
    margin-top: 40px;
    position: relative;

    .left-col {
      position: relative;

      .video-thumb {
        cursor: default;
      }

      .video-info {
        position: absolute;
        width: 100%;
        height: 100px;
        background-color: rgba(55, 55, 55, 0.7);
        bottom: 0;

        .video-name {
          text-transform: uppercase;
          font-weight: 700;
          font-size: 24px;
        }

        .video-data {
          width: 80%;
          height: 100px;
          float: left;
          padding: 10px 15px;

          .done-icon {
            height: 100px;
            float: left;
            padding-left: 15px;
            padding-right: 30px;

            img {
              position: relative;

              top: 15px;
            }
          }
        }

        .video-completion {
          width: 20%;
          height: 100px;
          float: right;
          padding: 15px 15px 0px 15px;
          font-size: 18px;
        }

        .video-seconds {
          font-weight: 700;
          font-size: 30px;
        }
      }
    }

    .user-stats {
      position: relative;
      width: 100%;
      height: 250px;
      background-color: rgba(55, 55, 55, 0.7);
      margin: 0 0 15px 0;

      div.col-xs-4, div.col-xs-8 {
        height: 250px;
      }

      .top-stats {
        
        .user-stat {
          padding-top: 35px;
        }
      }

      .user-stat {
        height: 125px;
        padding-top: 15px;
        //min-width: 170px;

        &.heartrate {
          display: block;
          .stat-value {
            float: left;
          }

          span {
            font-size: 16px;
            float: left;
            padding-left: 10px;
            padding-top: 6px;
            i {
              padding-left: 8px;
              color: red;
            }
          }
        }
      }

      .stat-value {
        font-weight: 700;
        font-size: 40px;

        span {
          font-weight: 300;
        }
      }

      .avatar-img {
        top: 50%;
        margin: 0 auto;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        .img-content {
          border-radius: 50%;
          border: 4px solid #fff;
        }
      }
    }

    .fitnessplan-completed {
      background-color: rgba(55, 55, 55, 0.7);
      padding: 60px 20px;
      margin: 0 0 15px 0;
    }

    .next-video-info {
      position: absolute;
      top: 0px;
      left: 0px;
      background-color: rgba(55, 55, 55, 0.7);
      padding: 15px 25px;
      font-size: 20px;

      span {
        font-weight: 700;
      }
    }
  }
}