.tree-map-component {
  z-index: 1;
  cursor: pointer;
  margin: 0 auto;
  width: 100%;
  height: 80vw;
  max-width: 95vw;

  /* Small devices (tablets, 768px and up) */
  @media (min-width: $screen-sm-min) { 
    height: 50vw;
    max-width: 90vw;
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    height: 40vw;
    max-width: 80vw;
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: $screen-lg-min) { 
    height: 30vw;
    max-width: 70vw;
  }
}