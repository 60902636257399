/************** @author: AKIS *****************/

/****  Margin  ****/
.m-auto {margin:auto;}
.m-center {margin: 0 auto;}
.m-0 {margin:0 !important;}
.m-5 {margin:5px !important;}
.m-10 {margin:10px !important;}
.m-20 {margin:20px !important;}

/****  Margin Top  ****/
.m-t-0 {margin-top:0px !important;}
.m-t-5{margin-top:5px !important; }
.m-t-10{margin-top:10px !important;}
.m-t-15{margin-top:15px !important;}
.m-t-20{margin-top:20px !important;}
.m-t-30{margin-top:30px !important;}
.m-t-40{margin-top:40px !important;}
.m-t-60{margin-top:60px !important;}

/****  Margin Bottom  ****/
.m-b-0 {margin-bottom:0px !important;}
.m-b-5 {margin-bottom:5px !important;}
.m-b-6 {margin-bottom:6px !important;}
.m-b-10 {margin-bottom:10px !important;}
.m-b-15{margin-bottom:15px !important;}
.m-b-12 {margin-bottom:12px !important;}
.m-b-15 {margin-bottom:15px !important;}
.m-b-20 {margin-bottom:20px !important;}
.m-b-30 {margin-bottom:30px !important;}
.m-b-40 {margin-bottom:40px !important;}
.m-b-60 {margin-bottom:60px !important;}
.m-b-80 {margin-bottom:80px !important;}
.m-b-140{margin-bottom:140px !important;}
.m-b-80 {margin-bottom:80px !important;}
.m-b-245 {margin-bottom:245px !important;}
.m-b-245 {margin-bottom:245px !important;}
.m-b-m30 {margin-bottom:-30px !important;}
.m-b-m50 {margin-bottom:-50px !important;}

/****  Margin Left  ****/
.m-l-5 {margin-left:5px !important;}
.m-l-10 {margin-left:10px !important;}
.m-l-15{margin-left:15px !important;}
.m-l-20 {margin-left:20px !important;}
.m-l-30 {margin-left:30px !important;}
.m-l-60 {margin-left:60px !important;}

/****  Margin Right  ****/
.m-r-5 {margin-right:5px !important;}
.m-r-10 {margin-right:10px !important;}
.m-r-15{margin-right:15px !important;}
.m-r-20 {margin-right:20px !important;}
.m-r-30 {margin-right:30px !important;}
.m-r-60 {margin-right:60px !important;}

/****  Padding  ****/
.p-0 {padding:0 !important;}
.p-5 {padding:5px !important;}
.p-10 {padding:10px !important;}
.p-15 {padding:15px !important;}
.p-20 {padding:20px !important;}
.p-30 {padding:30px !important;}
.p-40 {padding:40px !important;}

/****  Padding Top  ****/
.p-t-0 {padding-top:0 !important;}
.p-t-10 {padding-top:10px !important;}
.p-t-20 {padding-top:20px !important;}
.p-t-30 {padding-top:30px !important;}
.p-t-32 {padding-top:32px !important;}
.p-t-40 {padding-top:40px !important;}

/****  Padding Bottom  ****/
.p-b-0 {padding-bottom:0 !important;}
.p-b-10 {padding-bottom:10px !important;}
.p-b-20 {padding-bottom:20px !important;}
.p-b-30 {padding-bottom:30px !important;}
.p-b-40 {padding-bottom:40px !important;}
.p-b-60 {padding-bottom:60px !important;}
 
/****  Padding Left  ****/
.p-l-0 {padding-left:0px !important;}
.p-l-5 {padding-left:5px !important;}
.p-l-10 {padding-left:10px !important;}
.p-l-15 {padding-left:15px !important;}
.p-l-20 {padding-left:20px !important;}
.p-l-30 {padding-left:30px !important;}
.p-l-40 {padding-left:40px !important;}
.p-l-60 {padding-right:60px !important;}
/* Padding Right  ****/
.p-r-0 {padding-right:0px !important;}
.p-r-5 {padding-right:5px !important;}
.p-r-10 {padding-right:10px !important;}
.p-r-15 {padding-left:15px !important;}
.p-r-20 {padding-right:20px !important;}
.p-r-30 {padding-right:30px !important;}
.p-r-40 {padding-right:40px !important;}
.p-r-60 {padding-right:60px !important;}

/****  Border Radius  ****/
.bd-0{-moz-border-radius: 0 !important;-webkit-border-radius: 0 !important;border-radius: 0 !important;}
.bd-2{-moz-border-radius: 2px !important;-webkit-border-radius: 2px !important;border-radius: 2px !important;}


/****  Border Radius  ****/
.no-bd{border:none !important;box-shadow: none;}
.no-bd-left{border-left: none !important;}
.no-bd-right{border-right:none !important;}

/****  Background Colors  ****/
.bg-transparent  {background-color:transparent !important;}
.bg-general  {background-color:#f4f5f1 !important;}
.bg-gray  {background-color:#b6b6b6 !important;}
.bg-gray-light  {background-color:#ECECEC !important;}
.bg-red {background-color:#C75757 !important;}
.bg-white {background-color:#fff !important;}
.bg-green  {background-color:#79a242 !important;}
.bg-blue  {background-color:#0090D9 !important ;}
.bg-orange {background-color:#f1981f !important ;}
.bg-purple  {background-color:#B57EE0 !important;}
.bg-dark  {background-color:#424143 !important;}
.bg-opacity-20 {background-color: rgba(0, 0, 0, 0.2);}

/****  Opacity  ****/
.opacity-0 {@include opacity(0);}
.opacity-1 {@include opacity(1);}

.opacity-1-anim {
  @include opacity(1);
  @include transition(opacity .7s ease)
}

/****  Width  ****/
.width-50p  {width:50% !important;}
.width-100p  {width:100% !important;}

/****  Postion  ****/
.pos-rel{position:relative;}
.pos-abs {position:absolute;}
.dis-inline {display:inline;}
.dis-inline-b {display:inline-block !important;}
.dis-block {display:block !important;}
.f-left {float:left !important;}
.f-right {float:right !important;}
.cursor-pointer{cursor:pointer;}
.underline{text-decoration:underline !important;}
code  {padding: 2px 8px 2px 4px;font-size: 90%;color: #2A465C;background-color: #D5E9FF;white-space: nowrap;border-radius: 4px;}
.line-separator {border-right:1px solid #DBE2E7;}

a, a:focus, a:hover, a:active {  outline: 0;}

/****  Font Size  ****/
.f-8{font-size:8px !important;}
.f-10{font-size:10px !important;}
.f-12{font-size:12px !important;}
.f-14{font-size:14px !important;}
.f-15{font-size:15px !important;}
.f-16{font-size:16px !important;}
.f-18{font-size:18px !important;}
.f-20{font-size:20px !important;}
.f-24{font-size:24px !important;}
.f-28{font-size:28px !important;}
.f-32{font-size:32px !important;}
.f-40{font-size:40px !important;}
.f-60{font-size:60px !important;}
.f-65{font-size:65px !important;}
.f-80{font-size:80px !important;}
.f-150{font-size:150px !important;}

/****  Font Weight  ****/
.w-300 {font-weight: 300 !important;}
.w-500 {font-weight:500 !important;}
.w-600 {font-weight:600 !important;}
.w-700 {font-weight:700 !important;}

/****  Font Color  ****/
.c-red{color: #FF3B22 !important;}
.c-blue{color:#12beee !important;}
.c-purple {color:#9474b4 !important;}
.c-brown{color:#9E7B2E !important;}
.c-orange{color:#f1981f !important;}
.c-green {color:#1db954 !important;}
.c-gray-light {color:#D8D8D8 !important;}
.c-gray {color:#A6A6A6 !important;}
.c-dark {color:#545454 !important;}
.c-white {color:#fff !important;}
.transparent-color {color: rgba(0, 0, 0, 0.2);}
.line-through{text-decoration: line-through;}
.t-ellipsis {text-overflow: ellipsis;display: block;white-space: nowrap;overflow: hidden;}
.asterisk {color: #f37135;}
hr {border-top: 1px solid #cbcfcd;margin-top: 10px;margin-bottom: 10px;}
.solid-b {border-bottom: 2px solid #9474b4;}
.solid-t {border-top: 2px solid #9474b4;}
.dotted-b {border-bottom: 2px dotted #9474b4;}
.dotted-t {border-top: 2px dotted #9474b4;}
.dashed {border: 2px dashed #9474b4;}


// Shadow effects

.no-shadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

// Social buttons
.btn-social{position:relative;text-align:center;white-space:nowrap;text-overflow:ellipsis}
.btn-social.loading{padding-left:12px !important;text-align:center !important;}
.btn-social i.fa.fa-facebook{position:absolute;left:0;top:0;bottom:0;width:42px;line-height:34px;font-size:1.6em;text-align:center;padding-top: 5px;}
.btn-social.btn-sm{padding-left:38px}.btn-social.btn-sm>:first-child{line-height:28px;width:28px;font-size:1.4em}
.btn-social.btn-xs{padding-left:30px}.btn-social.btn-xs>:first-child{line-height:20px;width:20px;font-size:1.2em}

.btn-facebook{color:#fff;background-color:#3b5998;border-color:rgba(0,0,0,0.2)}.btn-facebook:hover,.btn-facebook:focus,.btn-facebook:active,.btn-facebook.active,.open>.dropdown-toggle.btn-facebook{color:#fff;background-color:#2d4373;border-color:rgba(0,0,0,0.2)}
.btn-facebook:active,.btn-facebook.active,.open>.dropdown-toggle.btn-facebook{background-image:none}
.btn-facebook.disabled,.btn-facebook[disabled],fieldset[disabled] .btn-facebook,.btn-facebook.disabled:hover,.btn-facebook[disabled]:hover,fieldset[disabled] .btn-facebook:hover,.btn-facebook.disabled:focus,.btn-facebook[disabled]:focus,fieldset[disabled] .btn-facebook:focus,.btn-facebook.disabled:active,.btn-facebook[disabled]:active,fieldset[disabled] .btn-facebook:active,.btn-facebook.disabled.active,.btn-facebook[disabled].active,fieldset[disabled] .btn-facebook.active{background-color:#3b5998;border-color:rgba(0,0,0,0.2)}
.btn-facebook .badge{color:#3b5998;background-color:#fff}

.btn-google{color:#fff;background-color:#dd4b39;border-color:rgba(0,0,0,0.2)}.btn-google:hover,.btn-google:focus,.btn-google:active,.btn-google.active,.open>.dropdown-toggle.btn-google{color:#fff;background-color:#c23321;border-color:rgba(0,0,0,0.2)}
.btn-google:active,.btn-google.active,.open>.dropdown-toggle.btn-google{background-image:none}
.btn-google.disabled,.btn-google[disabled],fieldset[disabled] .btn-google,.btn-google.disabled:hover,.btn-google[disabled]:hover,fieldset[disabled] .btn-google:hover,.btn-google.disabled:focus,.btn-google[disabled]:focus,fieldset[disabled] .btn-google:focus,.btn-google.disabled:active,.btn-google[disabled]:active,fieldset[disabled] .btn-google:active,.btn-google.disabled.active,.btn-google[disabled].active,fieldset[disabled] .btn-google.active{background-color:#dd4b39;border-color:rgba(0,0,0,0.2)}
.btn-google .badge{color:#dd4b39;background-color:#fff}

.btn-twitter{color:#fff;background-color:#55acee;border-color:rgba(0,0,0,0.2)}.btn-twitter:hover,.btn-twitter:focus,.btn-twitter:active,.btn-twitter.active,.open>.dropdown-toggle.btn-twitter{color:#fff;background-color:#2795e9;border-color:rgba(0,0,0,0.2)}
.btn-twitter:active,.btn-twitter.active,.open>.dropdown-toggle.btn-twitter{background-image:none}
.btn-twitter.disabled,.btn-twitter[disabled],fieldset[disabled] .btn-twitter,.btn-twitter.disabled:hover,.btn-twitter[disabled]:hover,fieldset[disabled] .btn-twitter:hover,.btn-twitter.disabled:focus,.btn-twitter[disabled]:focus,fieldset[disabled] .btn-twitter:focus,.btn-twitter.disabled:active,.btn-twitter[disabled]:active,fieldset[disabled] .btn-twitter:active,.btn-twitter.disabled.active,.btn-twitter[disabled].active,fieldset[disabled] .btn-twitter.active{background-color:#55acee;border-color:rgba(0,0,0,0.2)}
.btn-twitter .badge{color:#55acee;background-color:#fff}

.btn-transparent {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  border: 2px solid #888888 ;
  @include transition(background-color .3s ease);

  &:hover, &:focus {
    color: #fff;
    background-color: rgba(0,0,0,0.2);
    border-color: #fff;
  }

  &.no-bg {
    &:hover, &:focus {
      background-color: transparent;
    }
  }
}

.btn-purple {
  color: #fff;
  background-color: rgba(87,68,189, 1);
  border-color: rgba(87,68,189, 1);
  border: 2px solid rgba(87,68,189, 1) ;
  @include transition(background-color .3s ease);

  &:hover, &:focus {
    color: #fff;
    background-color: rgba(87,68,189, .8);
    border-color: #fff;
  }

  &.no-bg {
    &:hover, &:focus {
      background-color: rgba(87,68,189, 1);
    }
  }
}

.no-bg {
  background-color: transparent !important;
}

// Vertical Align
.valign-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center; 
}

.valign-wrapper .valign {
  display: block; 
}

button.facebook-btn, button.google-plus-btn, button.email-btn {
  border:0;
  background-color: rgba(0,0,0,0);
}

button.facebook-btn img, button.google-plus-btn img, button.email-btn img{
  width: 34px;
  height: 34px;
}

div.social-btn {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 2px;
}


.social-btn .facebook-btn {
  width: 32px;
  height: 32px;
  background: url(img/social/facebook.png) top left no-repeat;
}

.social-btn .google-plus-btn {
  width: 32px;
  height: 32px;
  background: url(img/social/googleplus.png) top left no-repeat;
}

.social-btn .email-btn {
  width: 32px;
  height: 32px;
  background: url(img/social/mail.png) top left no-repeat;
}

.social-btn .link-btn {
  width: 32px;
  height: 32px;
  background: url(img/social/share.png) top left no-repeat;
  border: none !important;
}

.social-btn .facebook-btn:hover, .social-btn .email-btn:hover, .social-btn .link-btn:hover, .social-btn .google-plus-btn:hover{
  background-position: 0px -32px
}

/****  Custom Scrollbar Browser  ****/
::-webkit-scrollbar {width:10px;}  
::-webkit-scrollbar-track {background-color: $gray;border: none;}
::-webkit-scrollbar-thumb {background-color: $gray-light;}  
::-webkit-scrollbar-thumb:hover { background-color: #00B9E7; }  
::-webkit-scrollbar-track {border-radius: 0;box-shadow: none;border: 0;}
::-webkit-scrollbar-thumb {border-radius: 0px;box-shadow: none;border: 0;} 

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes heartbeat {
  80% { transform: scale(1, 1);}
  85% {  transform: scale(1.2, 1.2); }
  90% {  transform: scale(1, 1); }
  95% {  transform: scale(1.2, 1.2); }
  100% { transform: scale(1, 1);}
}

@-webkit-keyframes heartbeat {
  80% { transform: scale(1, 1);}
  85% {  transform: scale(1.2, 1.2); }
  90% {  transform: scale(1, 1); }
  95% {  transform: scale(1.2, 1.2); }
  100% { transform: scale(1, 1);}
}
