.background-image-effect-component {
  -webkit-perspective: 10px;
  perspective: 10px;

  &, &:before {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
  }

  &:before {
    content: '';
    overflow: hidden;
    position: absolute;
    z-index: -1;
    /* transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1); */
    height: 100%;
    top: 0; 
    right: 0; 
    left: 0;
    bottom: 0px;
    /* filter: blur(30px);
    -webkit-filter: blur(30px);
    -moz-filter: blur(30px);
    -o-filter: blur(30px);
    -ms-filter: blur(30px); */
    
    // GPU acceleration
    /* -webkit-transform: translateZ(0px);
    -moz-transform: translateZ(0px);
    -ms-transform: translateZ(0px);
    -o-transform: translateZ(0px);
    transform: translateZ(0px); */
  }
}