.juicer-social-feed {

  .j-loading {
    -moz-animation:none;
    -webkit-animation:none;
    animation:none;
    border-color: $brand-primary;
    border-radius: 50%;
    overflow: hidden;
  }

  .j-loading:before {
    background-color: $brand-primary;
  }

  .juicer-feed.white li.feed-item, .juicer-feed.user li.feed-item, .juicer-feed.juicer-widget li.feed-item, .juicer-feed.colors li.feed-item, .juicer-feed.colored-icons li.feed-item, .juicer-feed.gray li.feed-item {
    background: $gray;
    border-color: $gray;
    border-width: 2px;
    color: #fff;
  }

  .j-poster h3 {
    color: #D9D9D9;
  }

  .juicer-feed a {
    color: $brand-primary;

    &:hover, &:focus {
      color: $brand-primary;
    }
  }

  #alttext-container {
    border-color: #000 !important;
  }

  .juicer-feed .j-meta a:hover {
    color: $brand-primary;
  }

  .j-overlay .j-close {
    font-size: 20px;
  }
}

.juicer-feed .j-meta a:hover {
  color: $brand-primary !important;
}
.juicer-feed a:hover {
  color: $brand-primary !important;
}

.j-share {
  display: none;
}

.j-overlay .j-overlay-content {
  border-radius: 5px !important;
}

.post-overlay.juicer-feed a.juicer-button {
  border-color: $brand-primary !important;
  color: $brand-primary !important;
}

.post-overlay.juicer-feed a.juicer-button:hover {
  background: $brand-primary !important;
  color: #fff !important;
}